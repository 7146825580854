<template>
  <div class="new-enterprise">
    <div class="header">
      <div class="header_View">
        <el-row>
          <el-col :span="4">
            <div class="grid-content bg-purple" @click="back">
              <i class="el-icon-back" />
            </div>
          </el-col>
          <el-col :span="16"
            ><div class="grid-content bg-purple">新增组织</div></el-col
          >
          <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
        </el-row>
      </div>
    </div>
    <div class="container">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-position="top">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="creditCode">
          <el-input
            v-model="form.creditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="区域" prop="region">
          <!-- <el-cascader
            v-model="form.region"
            style="width: 100%"
            :options="options"
            placeholder="请选择区域"
            clearable
          ></el-cascader> -->

          <el-select
            style="width: 100%"
            v-model="form.region"
            remote
            placeholder="请输入所属部门"
            :remote-method="remoteMethod"
            :loading="loading"
            @click="showCascader = true"
          >
          </el-select>
          <!-- <van-field
            v-model="fieldValue"
            is-link
            readonly
            label="地区"
            placeholder="请选择所在地区"
            @click="show = true"
          /> -->
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            v-model="form.address"
            placeholder="*填写时不包含区域&#10;*例如区域中的已选择xx市xx区&#10;*则此处直接填写后台的地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="businesslicense">
          <!-- drag -->
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            @change="beforeAvatarUpload"
            :auto-upload="false"
          >
            <div class="uploader-content-div" v-if="!form.businesslicense">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
            <div class="uploader-imgContent-div" v-else>
              <img :src="form.businesslicense" />
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button
          type="primary"
          round
          @click="confirm"
          style="margin: 30px 0; width: 100%"
          >创建</el-button
        >
      </div>
    </div>
    <van-popup v-model:show="showCascader" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="showCascader = false"
        @finish="cascaderOnFinish"
      />
    </van-popup>
  </div>
</template>
<script>
import addressList from "../../assets/js/address";
export default {
  name: "new-enterprise",
  data() {
    return {
      showCascader: false, // 是否关闭选择地区
      cascaderValue: "", // 选择地区关联值（保存选择值）
      businesslicense: "", // 保存营业执照地址
      options: [], // 地区数据配置
      form: {
        name: "",
        creditCode: "",
        mobile: "",
        region: "",
        address: "",
        businesslicense: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        creditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择区域", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        businesslicense: [
          { required: true, message: "请上传营业执照", trigger: "chang" },
        ],
      },
    };
  },
  created() {
    if (addressList) {
      this.options = addressList;
    }
  },
  methods: {
    cascaderOnFinish(item) {
      this.showCascader = false;
      const regionText = item?.selectedOptions
        .map((option) => option.text)
        .join("/");
      this.form.region = regionText;
    },
    beforeAvatarUpload(files) {
      const file = files.raw;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("图片只能是 jpg jpeg png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "idf");
      this.$https
        .post("/api/upload", formData)
        .then((res) => {
          if (res.success) {
            this.form.businesslicense = res.data.url;
            this.businesslicense = res.data.path;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
      return isJPG && isLt2M;
    },
    back() {
      this.$emit("onBack");
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const regionList = this.form.region.split("/");
          const province = regionList[0];
          const city = regionList[1] || "";
          const area = regionList[2] || "";
          this.$https
            .post("/api/my/organization/create", {
              title: this.form.name,
              creditCode: this.form.creditCode,
              mobile: this.form.mobile,
              province: province,
              city: city,
              area: area,
              address: this.form.address,
              bussLicenseImage: this.businesslicense,
            })
            .then((res) => {
              if (res.success) {
                this.$refs.ruleForm.resetFields();
                this.businesslicense = "";
                this.$message.success("创建成功");
                this.back();
                // window.location.reload();
              }
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.new-enterprise {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .header {
    text-align: center;
    font-size: 18px;
    color: #677897;
    font-weight: bold;
    .header_View {
      position: relative;
      height: 50px;
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #ccc;
    }
  }
  .avatar-uploader {
    width: 100%;
  }
  .uploader-content-div {
    width: 100%;
    height: 146px;
    border: 1px dashed #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    width: 100%;
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 146px;
    line-height: 146px;
    text-align: center;
  }
  .uploader-imgContent-div {
    max-width: 100%;
    // height: 146px;
    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }
  .el-upload {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 15px;
  }
  .el-form-item__label {
    padding: 0 !important;
  }
  .container {
    flex: 1;
    overflow: auto;
    padding: 10px 20px;
    // background-color: #f4f4f4;
  }
  .footer {
    text-align: center;
  }
}
</style>
