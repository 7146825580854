<template>
  <div class="enterprise">
    <div class="header">
      <div class="header_View">
        <el-row>
          <el-col :span="4">
            <div class="grid-content bg-purple" @click="back">
              <i class="el-icon-back" />
            </div>
          </el-col>
          <el-col :span="16"
            ><div class="grid-content bg-purple">所属部门</div></el-col
          >
          <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
        </el-row>
      </div>
      <div class="header_search">
        <el-input
          class="search_input"
          placeholder="请输入内容"
          v-model="searchVal"
          @clear="clearInput"
          clearable
        >
          <template v-slot:prefix>
            <i class="el-input__icon el-icon-search"></i>
          </template>
        </el-input>
        <el-button class="search_button" type="primary" @click="searchFun"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="container">
      <div class="containerDiv" v-loading="isLoading">
        <div style="height: 100%" :hidden="isLoading">
          <div class="enterpriseList" :hidden="enterpriseList.length == 0">
            <div
              class="enterpriseItem"
              v-for="(item, index) in enterpriseList"
              :key="index"
              @click="itemClick(item)"
            >
              <el-card class="box-card">
                <div class="itemName itemVal">
                  <div class="name">企业名称</div>
                  <div class="text">{{ item.title }}</div>
                </div>
                <div class="unifiedCode itemVal">
                  <div class="name">统一代码</div>
                  <div class="text">{{ item.creditCode }}</div>
                </div>
                <div class="detailedAddress itemVal">
                  <div class="name">详情地址</div>
                  <div class="text">
                    {{
                      [item.province, item.city, item.area]
                        .filter((p) => (p || "") != "")
                        .join("-")
                    }}
                    {{ item.address }}
                  </div>
                </div>
              </el-card>
            </div>
          </div>
          <div class="enterpriseNewButton">
            <el-button
              style="width: 100%"
              :hidden="enterpriseList.length == 0"
              type="primary"
              @click="toNewEnterprise"
              >点击此处新建</el-button
            >
          </div>
          <div class="NO_enterprise" :hidden="enterpriseList.length > 0">
            <div class="NO_enterprise_text">
              暂未查询到 [ {{ searchText }} ] 相关企业
            </div>
            <el-button type="primary" round @click="toNewEnterprise"
              >点击此处新建</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      size="100%"
      :with-header="false"
      :append-to-body="true"
      custom-class="enterpriseBody-drawer"
      :direction="'enterprise-drawer'"
      :before-close="handleClose"
      v-model="isShowDrawer"
    >
      <NewEnterprise @onBack="onBack" />
    </el-drawer>
  </div>
</template>
<script>
import NewEnterprise from "./newEnterprise";

export default {
  name: "enterprise",
  data() {
    return {
      drawerRef: null,
      isShowDrawer: false, // 是否显示抽屉（创建企业）
      searchVal: "", // 关联输入框数据
      searchText: "", // 搜索值
      enterpriseList: [], // 搜索列表
      isLoading: false,
    };
  },
  created() {
    this.getDataList(this.searchText);
  },
  methods: {
    // 选择企业
    itemClick(item) {
      this.$emit("itemClick", item);
    },
    searchFun() {
      this.searchText = this.searchVal;
      this.getDataList(this.searchText);
    },
    // 清空搜索框数据
    clearInput() {
      this.getDataList("");
    },
    // 请求数据
    getDataList(title) {
      this.isLoading = true;
      this.$https
        .get("/api/my/organization/list?title=" + title)
        .then((res) => {
          this.isLoading = false;
          if (res.success) {
            this.enterpriseList = res.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    toNewEnterprise() {
      this.isShowDrawer = true;
    },
    // 在创建企业返回
    onBack() {
      this.getDataList(this.searchText);
      this.isShowDrawer = false;
    },
    back() {
      this.$emit("onBack");
    },
  },
  components: {
    NewEnterprise,
  },
};
</script>
<style lang="less">
.enterprise-drawer {
  width: 100%;
  .el-drawer__body {
    height: 0;
  }
}
.enterpriseBody-drawer {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}
.enterprise {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .header {
    text-align: center;
    font-size: 18px;
    color: #677897;
    font-weight: bold;
    .header_View {
      position: relative;
      height: 50px;
      line-height: 50px;
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #ccc;
    }
    .header_search {
      position: relative;
      padding: 15px 20px;
      padding-right: 135px;
      height: 70px;
      background-color: #fff;
    }
    .search_input {
      height: 32px;
    }
    .search_button {
      position: absolute;
      top: 15px;
      right: 20px;
      width: 100px;
      height: 32px;
    }
  }
  .el-form-item {
    margin-bottom: 15px;
  }
  .el-form-item__label {
    padding: 0 !important;
  }
  & > .container {
    flex: 1;
    background-color: #f4f4f4;
    position: relative;
    height: 0;
    padding: 0 0 60px 0 !important;
    .containerDiv {
      width: 100%;
      height: 100%;
      padding: 20px 20px;
      overflow: auto;
    }
    *[hidden] {
      display: none !important;
    }
    .enterpriseList {
      // padding: 15px 0;
      .enterpriseItem {
        padding: 0 0 15px;
        .box-card {
          padding: 15px 0;
        }
      }
      .itemVal {
        padding: 15px 20px;
        font-size: 16px;
        display: flex;
        .name {
          flex: 0 0 90px;
          color: #999;
        }
        .text {
          flex: 1;
          word-wrap: break-word;
          word-break: break-word;
          white-space: pre-wrap;
          white-space: pre-line;
        }
      }
    }
    .enterpriseNewButton {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      width: 100%;
      height: 60px;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 10px 20px;
    }
    .NO_enterprise {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .NO_enterprise_text {
        margin-bottom: 20px;
        color: #929292;
      }
    }
  }
  .footer {
    text-align: center;
  }
}
</style>
