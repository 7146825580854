const address = [
  {
    value: "11",
    text: "北京市",
    children: [
      {
        value: "1101",
        text: "市辖区",
        children: [
          { value: "110101", text: "东城区" },
          { value: "110102", text: "西城区" },
          { value: "110105", text: "朝阳区" },
          { value: "110106", text: "丰台区" },
          { value: "110107", text: "石景山区" },
          { value: "110108", text: "海淀区" },
          { value: "110109", text: "门头沟区" },
          { value: "110111", text: "房山区" },
          { value: "110112", text: "通州区" },
          { value: "110113", text: "顺义区" },
          { value: "110114", text: "昌平区" },
          { value: "110115", text: "大兴区" },
          { value: "110116", text: "怀柔区" },
          { value: "110117", text: "平谷区" },
          { value: "110118", text: "密云区" },
          { value: "110119", text: "延庆区" },
        ],
      },
    ],
  },
  {
    value: "12",
    text: "天津市",
    children: [
      {
        value: "1201",
        text: "市辖区",
        children: [
          { value: "120101", text: "和平区" },
          { value: "120102", text: "河东区" },
          { value: "120103", text: "河西区" },
          { value: "120104", text: "南开区" },
          { value: "120105", text: "河北区" },
          { value: "120106", text: "红桥区" },
          { value: "120110", text: "东丽区" },
          { value: "120111", text: "西青区" },
          { value: "120112", text: "津南区" },
          { value: "120113", text: "北辰区" },
          { value: "120114", text: "武清区" },
          { value: "120115", text: "宝坻区" },
          { value: "120116", text: "滨海新区" },
          { value: "120117", text: "宁河区" },
          { value: "120118", text: "静海区" },
          { value: "120119", text: "蓟州区" },
        ],
      },
    ],
  },
  {
    value: "13",
    text: "河北省",
    children: [
      {
        value: "1301",
        text: "石家庄市",
        children: [
          { value: "130102", text: "长安区" },
          { value: "130104", text: "桥西区" },
          { value: "130105", text: "新华区" },
          { value: "130107", text: "井陉矿区" },
          { value: "130108", text: "裕华区" },
          { value: "130109", text: "藁城区" },
          { value: "130110", text: "鹿泉区" },
          { value: "130111", text: "栾城区" },
          { value: "130121", text: "井陉县" },
          { value: "130123", text: "正定县" },
          { value: "130125", text: "行唐县" },
          { value: "130126", text: "灵寿县" },
          { value: "130127", text: "高邑县" },
          { value: "130128", text: "深泽县" },
          { value: "130129", text: "赞皇县" },
          { value: "130130", text: "无极县" },
          { value: "130131", text: "平山县" },
          { value: "130132", text: "元氏县" },
          { value: "130133", text: "赵县" },
          { value: "130171", text: "石家庄高新技术产业开发区" },
          { value: "130172", text: "石家庄循环化工园区" },
          { value: "130181", text: "辛集市" },
          { value: "130183", text: "晋州市" },
          { value: "130184", text: "新乐市" },
        ],
      },
      {
        value: "1302",
        text: "唐山市",
        children: [
          { value: "130202", text: "路南区" },
          { value: "130203", text: "路北区" },
          { value: "130204", text: "古冶区" },
          { value: "130205", text: "开平区" },
          { value: "130207", text: "丰南区" },
          { value: "130208", text: "丰润区" },
          { value: "130209", text: "曹妃甸区" },
          { value: "130224", text: "滦南县" },
          { value: "130225", text: "乐亭县" },
          { value: "130227", text: "迁西县" },
          { value: "130229", text: "玉田县" },
          { value: "130271", text: "河北唐山芦台经济开发区" },
          { value: "130272", text: "唐山市汉沽管理区" },
          { value: "130273", text: "唐山高新技术产业开发区" },
          { value: "130274", text: "河北唐山海港经济开发区" },
          { value: "130281", text: "遵化市" },
          { value: "130283", text: "迁安市" },
          { value: "130284", text: "滦州市" },
        ],
      },
      {
        value: "1303",
        text: "秦皇岛市",
        children: [
          { value: "130302", text: "海港区" },
          { value: "130303", text: "山海关区" },
          { value: "130304", text: "北戴河区" },
          { value: "130306", text: "抚宁区" },
          { value: "130321", text: "青龙满族自治县" },
          { value: "130322", text: "昌黎县" },
          { value: "130324", text: "卢龙县" },
          { value: "130371", text: "秦皇岛市经济技术开发区" },
          { value: "130372", text: "北戴河新区" },
        ],
      },
      {
        value: "1304",
        text: "邯郸市",
        children: [
          { value: "130402", text: "邯山区" },
          { value: "130403", text: "丛台区" },
          { value: "130404", text: "复兴区" },
          { value: "130406", text: "峰峰矿区" },
          { value: "130407", text: "肥乡区" },
          { value: "130408", text: "永年区" },
          { value: "130423", text: "临漳县" },
          { value: "130424", text: "成安县" },
          { value: "130425", text: "大名县" },
          { value: "130426", text: "涉县" },
          { value: "130427", text: "磁县" },
          { value: "130430", text: "邱县" },
          { value: "130431", text: "鸡泽县" },
          { value: "130432", text: "广平县" },
          { value: "130433", text: "馆陶县" },
          { value: "130434", text: "魏县" },
          { value: "130435", text: "曲周县" },
          { value: "130471", text: "邯郸经济技术开发区" },
          { value: "130473", text: "邯郸冀南新区" },
          { value: "130481", text: "武安市" },
        ],
      },
      {
        value: "1305",
        text: "邢台市",
        children: [
          { value: "130502", text: "桥东区" },
          { value: "130503", text: "桥西区" },
          { value: "130521", text: "邢台县" },
          { value: "130522", text: "临城县" },
          { value: "130523", text: "内丘县" },
          { value: "130524", text: "柏乡县" },
          { value: "130525", text: "隆尧县" },
          { value: "130526", text: "任县" },
          { value: "130527", text: "南和县" },
          { value: "130528", text: "宁晋县" },
          { value: "130529", text: "巨鹿县" },
          { value: "130530", text: "新河县" },
          { value: "130531", text: "广宗县" },
          { value: "130532", text: "平乡县" },
          { value: "130533", text: "威县" },
          { value: "130534", text: "清河县" },
          { value: "130535", text: "临西县" },
          { value: "130571", text: "河北邢台经济开发区" },
          { value: "130581", text: "南宫市" },
          { value: "130582", text: "沙河市" },
        ],
      },
      {
        value: "1306",
        text: "保定市",
        children: [
          { value: "130602", text: "竞秀区" },
          { value: "130606", text: "莲池区" },
          { value: "130607", text: "满城区" },
          { value: "130608", text: "清苑区" },
          { value: "130609", text: "徐水区" },
          { value: "130623", text: "涞水县" },
          { value: "130624", text: "阜平县" },
          { value: "130626", text: "定兴县" },
          { value: "130627", text: "唐县" },
          { value: "130628", text: "高阳县" },
          { value: "130629", text: "容城县" },
          { value: "130630", text: "涞源县" },
          { value: "130631", text: "望都县" },
          { value: "130632", text: "安新县" },
          { value: "130633", text: "易县" },
          { value: "130634", text: "曲阳县" },
          { value: "130635", text: "蠡县" },
          { value: "130636", text: "顺平县" },
          { value: "130637", text: "博野县" },
          { value: "130638", text: "雄县" },
          { value: "130671", text: "保定高新技术产业开发区" },
          { value: "130672", text: "保定白沟新城" },
          { value: "130681", text: "涿州市" },
          { value: "130682", text: "定州市" },
          { value: "130683", text: "安国市" },
          { value: "130684", text: "高碑店市" },
        ],
      },
      {
        value: "1307",
        text: "张家口市",
        children: [
          { value: "130702", text: "桥东区" },
          { value: "130703", text: "桥西区" },
          { value: "130705", text: "宣化区" },
          { value: "130706", text: "下花园区" },
          { value: "130708", text: "万全区" },
          { value: "130709", text: "崇礼区" },
          { value: "130722", text: "张北县" },
          { value: "130723", text: "康保县" },
          { value: "130724", text: "沽源县" },
          { value: "130725", text: "尚义县" },
          { value: "130726", text: "蔚县" },
          { value: "130727", text: "阳原县" },
          { value: "130728", text: "怀安县" },
          { value: "130730", text: "怀来县" },
          { value: "130731", text: "涿鹿县" },
          { value: "130732", text: "赤城县" },
          { value: "130771", text: "张家口经济开发区" },
          { value: "130772", text: "张家口市察北管理区" },
          { value: "130773", text: "张家口市塞北管理区" },
        ],
      },
      {
        value: "1308",
        text: "承德市",
        children: [
          { value: "130802", text: "双桥区" },
          { value: "130803", text: "双滦区" },
          { value: "130804", text: "鹰手营子矿区" },
          { value: "130821", text: "承德县" },
          { value: "130822", text: "兴隆县" },
          { value: "130824", text: "滦平县" },
          { value: "130825", text: "隆化县" },
          { value: "130826", text: "丰宁满族自治县" },
          { value: "130827", text: "宽城满族自治县" },
          { value: "130828", text: "围场满族蒙古族自治县" },
          { value: "130871", text: "承德高新技术产业开发区" },
          { value: "130881", text: "平泉市" },
        ],
      },
      {
        value: "1309",
        text: "沧州市",
        children: [
          { value: "130902", text: "新华区" },
          { value: "130903", text: "运河区" },
          { value: "130921", text: "沧县" },
          { value: "130922", text: "青县" },
          { value: "130923", text: "东光县" },
          { value: "130924", text: "海兴县" },
          { value: "130925", text: "盐山县" },
          { value: "130926", text: "肃宁县" },
          { value: "130927", text: "南皮县" },
          { value: "130928", text: "吴桥县" },
          { value: "130929", text: "献县" },
          { value: "130930", text: "孟村回族自治县" },
          { value: "130971", text: "河北沧州经济开发区" },
          { value: "130972", text: "沧州高新技术产业开发区" },
          { value: "130973", text: "沧州渤海新区" },
          { value: "130981", text: "泊头市" },
          { value: "130982", text: "任丘市" },
          { value: "130983", text: "黄骅市" },
          { value: "130984", text: "河间市" },
        ],
      },
      {
        value: "1310",
        text: "廊坊市",
        children: [
          { value: "131002", text: "安次区" },
          { value: "131003", text: "广阳区" },
          { value: "131022", text: "固安县" },
          { value: "131023", text: "永清县" },
          { value: "131024", text: "香河县" },
          { value: "131025", text: "大城县" },
          { value: "131026", text: "文安县" },
          { value: "131028", text: "大厂回族自治县" },
          { value: "131071", text: "廊坊经济技术开发区" },
          { value: "131081", text: "霸州市" },
          { value: "131082", text: "三河市" },
        ],
      },
      {
        value: "1311",
        text: "衡水市",
        children: [
          { value: "131102", text: "桃城区" },
          { value: "131103", text: "冀州区" },
          { value: "131121", text: "枣强县" },
          { value: "131122", text: "武邑县" },
          { value: "131123", text: "武强县" },
          { value: "131124", text: "饶阳县" },
          { value: "131125", text: "安平县" },
          { value: "131126", text: "故城县" },
          { value: "131127", text: "景县" },
          { value: "131128", text: "阜城县" },
          { value: "131171", text: "河北衡水高新技术产业开发区" },
          { value: "131172", text: "衡水滨湖新区" },
          { value: "131182", text: "深州市" },
        ],
      },
    ],
  },
  {
    value: "14",
    text: "山西省",
    children: [
      {
        value: "1401",
        text: "太原市",
        children: [
          { value: "140105", text: "小店区" },
          { value: "140106", text: "迎泽区" },
          { value: "140107", text: "杏花岭区" },
          { value: "140108", text: "尖草坪区" },
          { value: "140109", text: "万柏林区" },
          { value: "140110", text: "晋源区" },
          { value: "140121", text: "清徐县" },
          { value: "140122", text: "阳曲县" },
          { value: "140123", text: "娄烦县" },
          { value: "140171", text: "山西转型综合改革示范区" },
          { value: "140181", text: "古交市" },
        ],
      },
      {
        value: "1402",
        text: "大同市",
        children: [
          { value: "140212", text: "新荣区" },
          { value: "140213", text: "平城区" },
          { value: "140214", text: "云冈区" },
          { value: "140215", text: "云州区" },
          { value: "140221", text: "阳高县" },
          { value: "140222", text: "天镇县" },
          { value: "140223", text: "广灵县" },
          { value: "140224", text: "灵丘县" },
          { value: "140225", text: "浑源县" },
          { value: "140226", text: "左云县" },
          { value: "140271", text: "山西大同经济开发区" },
        ],
      },
      {
        value: "1403",
        text: "阳泉市",
        children: [
          { value: "140302", text: "城区" },
          { value: "140303", text: "矿区" },
          { value: "140311", text: "郊区" },
          { value: "140321", text: "平定县" },
          { value: "140322", text: "盂县" },
        ],
      },
      {
        value: "1404",
        text: "长治市",
        children: [
          { value: "140403", text: "潞州区" },
          { value: "140404", text: "上党区" },
          { value: "140405", text: "屯留区" },
          { value: "140406", text: "潞城区" },
          { value: "140423", text: "襄垣县" },
          { value: "140425", text: "平顺县" },
          { value: "140426", text: "黎城县" },
          { value: "140427", text: "壶关县" },
          { value: "140428", text: "长子县" },
          { value: "140429", text: "武乡县" },
          { value: "140430", text: "沁县" },
          { value: "140431", text: "沁源县" },
          { value: "140471", text: "山西长治高新技术产业园区" },
        ],
      },
      {
        value: "1405",
        text: "晋城市",
        children: [
          { value: "140502", text: "城区" },
          { value: "140521", text: "沁水县" },
          { value: "140522", text: "阳城县" },
          { value: "140524", text: "陵川县" },
          { value: "140525", text: "泽州县" },
          { value: "140581", text: "高平市" },
        ],
      },
      {
        value: "1406",
        text: "朔州市",
        children: [
          { value: "140602", text: "朔城区" },
          { value: "140603", text: "平鲁区" },
          { value: "140621", text: "山阴县" },
          { value: "140622", text: "应县" },
          { value: "140623", text: "右玉县" },
          { value: "140671", text: "山西朔州经济开发区" },
          { value: "140681", text: "怀仁市" },
        ],
      },
      {
        value: "1407",
        text: "晋中市",
        children: [
          { value: "140702", text: "榆次区" },
          { value: "140721", text: "榆社县" },
          { value: "140722", text: "左权县" },
          { value: "140723", text: "和顺县" },
          { value: "140724", text: "昔阳县" },
          { value: "140725", text: "寿阳县" },
          { value: "140726", text: "太谷县" },
          { value: "140727", text: "祁县" },
          { value: "140728", text: "平遥县" },
          { value: "140729", text: "灵石县" },
          { value: "140781", text: "介休市" },
        ],
      },
      {
        value: "1408",
        text: "运城市",
        children: [
          { value: "140802", text: "盐湖区" },
          { value: "140821", text: "临猗县" },
          { value: "140822", text: "万荣县" },
          { value: "140823", text: "闻喜县" },
          { value: "140824", text: "稷山县" },
          { value: "140825", text: "新绛县" },
          { value: "140826", text: "绛县" },
          { value: "140827", text: "垣曲县" },
          { value: "140828", text: "夏县" },
          { value: "140829", text: "平陆县" },
          { value: "140830", text: "芮城县" },
          { value: "140881", text: "永济市" },
          { value: "140882", text: "河津市" },
        ],
      },
      {
        value: "1409",
        text: "忻州市",
        children: [
          { value: "140902", text: "忻府区" },
          { value: "140921", text: "定襄县" },
          { value: "140922", text: "五台县" },
          { value: "140923", text: "代县" },
          { value: "140924", text: "繁峙县" },
          { value: "140925", text: "宁武县" },
          { value: "140926", text: "静乐县" },
          { value: "140927", text: "神池县" },
          { value: "140928", text: "五寨县" },
          { value: "140929", text: "岢岚县" },
          { value: "140930", text: "河曲县" },
          { value: "140931", text: "保德县" },
          { value: "140932", text: "偏关县" },
          { value: "140971", text: "五台山风景名胜区" },
          { value: "140981", text: "原平市" },
        ],
      },
      {
        value: "1410",
        text: "临汾市",
        children: [
          { value: "141002", text: "尧都区" },
          { value: "141021", text: "曲沃县" },
          { value: "141022", text: "翼城县" },
          { value: "141023", text: "襄汾县" },
          { value: "141024", text: "洪洞县" },
          { value: "141025", text: "古县" },
          { value: "141026", text: "安泽县" },
          { value: "141027", text: "浮山县" },
          { value: "141028", text: "吉县" },
          { value: "141029", text: "乡宁县" },
          { value: "141030", text: "大宁县" },
          { value: "141031", text: "隰县" },
          { value: "141032", text: "永和县" },
          { value: "141033", text: "蒲县" },
          { value: "141034", text: "汾西县" },
          { value: "141081", text: "侯马市" },
          { value: "141082", text: "霍州市" },
        ],
      },
      {
        value: "1411",
        text: "吕梁市",
        children: [
          { value: "141102", text: "离石区" },
          { value: "141121", text: "文水县" },
          { value: "141122", text: "交城县" },
          { value: "141123", text: "兴县" },
          { value: "141124", text: "临县" },
          { value: "141125", text: "柳林县" },
          { value: "141126", text: "石楼县" },
          { value: "141127", text: "岚县" },
          { value: "141128", text: "方山县" },
          { value: "141129", text: "中阳县" },
          { value: "141130", text: "交口县" },
          { value: "141181", text: "孝义市" },
          { value: "141182", text: "汾阳市" },
        ],
      },
    ],
  },
  {
    value: "15",
    text: "内蒙古自治区",
    children: [
      {
        value: "1501",
        text: "呼和浩特市",
        children: [
          { value: "150102", text: "新城区" },
          { value: "150103", text: "回民区" },
          { value: "150104", text: "玉泉区" },
          { value: "150105", text: "赛罕区" },
          { value: "150121", text: "土默特左旗" },
          { value: "150122", text: "托克托县" },
          { value: "150123", text: "和林格尔县" },
          { value: "150124", text: "清水河县" },
          { value: "150125", text: "武川县" },
          { value: "150171", text: "呼和浩特金海工业园区" },
          { value: "150172", text: "呼和浩特经济技术开发区" },
        ],
      },
      {
        value: "1502",
        text: "包头市",
        children: [
          { value: "150202", text: "东河区" },
          { value: "150203", text: "昆都仑区" },
          { value: "150204", text: "青山区" },
          { value: "150205", text: "石拐区" },
          { value: "150206", text: "白云鄂博矿区" },
          { value: "150207", text: "九原区" },
          { value: "150221", text: "土默特右旗" },
          { value: "150222", text: "固阳县" },
          { value: "150223", text: "达尔罕茂明安联合旗" },
          { value: "150271", text: "包头稀土高新技术产业开发区" },
        ],
      },
      {
        value: "1503",
        text: "乌海市",
        children: [
          { value: "150302", text: "海勃湾区" },
          { value: "150303", text: "海南区" },
          { value: "150304", text: "乌达区" },
        ],
      },
      {
        value: "1504",
        text: "赤峰市",
        children: [
          { value: "150402", text: "红山区" },
          { value: "150403", text: "元宝山区" },
          { value: "150404", text: "松山区" },
          { value: "150421", text: "阿鲁科尔沁旗" },
          { value: "150422", text: "巴林左旗" },
          { value: "150423", text: "巴林右旗" },
          { value: "150424", text: "林西县" },
          { value: "150425", text: "克什克腾旗" },
          { value: "150426", text: "翁牛特旗" },
          { value: "150428", text: "喀喇沁旗" },
          { value: "150429", text: "宁城县" },
          { value: "150430", text: "敖汉旗" },
        ],
      },
      {
        value: "1505",
        text: "通辽市",
        children: [
          { value: "150502", text: "科尔沁区" },
          { value: "150521", text: "科尔沁左翼中旗" },
          { value: "150522", text: "科尔沁左翼后旗" },
          { value: "150523", text: "开鲁县" },
          { value: "150524", text: "库伦旗" },
          { value: "150525", text: "奈曼旗" },
          { value: "150526", text: "扎鲁特旗" },
          { value: "150571", text: "通辽经济技术开发区" },
          { value: "150581", text: "霍林郭勒市" },
        ],
      },
      {
        value: "1506",
        text: "鄂尔多斯市",
        children: [
          { value: "150602", text: "东胜区" },
          { value: "150603", text: "康巴什区" },
          { value: "150621", text: "达拉特旗" },
          { value: "150622", text: "准格尔旗" },
          { value: "150623", text: "鄂托克前旗" },
          { value: "150624", text: "鄂托克旗" },
          { value: "150625", text: "杭锦旗" },
          { value: "150626", text: "乌审旗" },
          { value: "150627", text: "伊金霍洛旗" },
        ],
      },
      {
        value: "1507",
        text: "呼伦贝尔市",
        children: [
          { value: "150702", text: "海拉尔区" },
          { value: "150703", text: "扎赉诺尔区" },
          { value: "150721", text: "阿荣旗" },
          { value: "150722", text: "莫力达瓦达斡尔族自治旗" },
          { value: "150723", text: "鄂伦春自治旗" },
          { value: "150724", text: "鄂温克族自治旗" },
          { value: "150725", text: "陈巴尔虎旗" },
          { value: "150726", text: "新巴尔虎左旗" },
          { value: "150727", text: "新巴尔虎右旗" },
          { value: "150781", text: "满洲里市" },
          { value: "150782", text: "牙克石市" },
          { value: "150783", text: "扎兰屯市" },
          { value: "150784", text: "额尔古纳市" },
          { value: "150785", text: "根河市" },
        ],
      },
      {
        value: "1508",
        text: "巴彦淖尔市",
        children: [
          { value: "150802", text: "临河区" },
          { value: "150821", text: "五原县" },
          { value: "150822", text: "磴口县" },
          { value: "150823", text: "乌拉特前旗" },
          { value: "150824", text: "乌拉特中旗" },
          { value: "150825", text: "乌拉特后旗" },
          { value: "150826", text: "杭锦后旗" },
        ],
      },
      {
        value: "1509",
        text: "乌兰察布市",
        children: [
          { value: "150902", text: "集宁区" },
          { value: "150921", text: "卓资县" },
          { value: "150922", text: "化德县" },
          { value: "150923", text: "商都县" },
          { value: "150924", text: "兴和县" },
          { value: "150925", text: "凉城县" },
          { value: "150926", text: "察哈尔右翼前旗" },
          { value: "150927", text: "察哈尔右翼中旗" },
          { value: "150928", text: "察哈尔右翼后旗" },
          { value: "150929", text: "四子王旗" },
          { value: "150981", text: "丰镇市" },
        ],
      },
      {
        value: "1522",
        text: "兴安盟",
        children: [
          { value: "152201", text: "乌兰浩特市" },
          { value: "152202", text: "阿尔山市" },
          { value: "152221", text: "科尔沁右翼前旗" },
          { value: "152222", text: "科尔沁右翼中旗" },
          { value: "152223", text: "扎赉特旗" },
          { value: "152224", text: "突泉县" },
        ],
      },
      {
        value: "1525",
        text: "锡林郭勒盟",
        children: [
          { value: "152501", text: "二连浩特市" },
          { value: "152502", text: "锡林浩特市" },
          { value: "152522", text: "阿巴嘎旗" },
          { value: "152523", text: "苏尼特左旗" },
          { value: "152524", text: "苏尼特右旗" },
          { value: "152525", text: "东乌珠穆沁旗" },
          { value: "152526", text: "西乌珠穆沁旗" },
          { value: "152527", text: "太仆寺旗" },
          { value: "152528", text: "镶黄旗" },
          { value: "152529", text: "正镶白旗" },
          { value: "152530", text: "正蓝旗" },
          { value: "152531", text: "多伦县" },
          { value: "152571", text: "乌拉盖管委会" },
        ],
      },
      {
        value: "1529",
        text: "阿拉善盟",
        children: [
          { value: "152921", text: "阿拉善左旗" },
          { value: "152922", text: "阿拉善右旗" },
          { value: "152923", text: "额济纳旗" },
          { value: "152971", text: "内蒙古阿拉善经济开发区" },
        ],
      },
    ],
  },
  {
    value: "21",
    text: "辽宁省",
    children: [
      {
        value: "2101",
        text: "沈阳市",
        children: [
          { value: "210102", text: "和平区" },
          { value: "210103", text: "沈河区" },
          { value: "210104", text: "大东区" },
          { value: "210105", text: "皇姑区" },
          { value: "210106", text: "铁西区" },
          { value: "210111", text: "苏家屯区" },
          { value: "210112", text: "浑南区" },
          { value: "210113", text: "沈北新区" },
          { value: "210114", text: "于洪区" },
          { value: "210115", text: "辽中区" },
          { value: "210123", text: "康平县" },
          { value: "210124", text: "法库县" },
          { value: "210181", text: "新民市" },
        ],
      },
      {
        value: "2102",
        text: "大连市",
        children: [
          { value: "210202", text: "中山区" },
          { value: "210203", text: "西岗区" },
          { value: "210204", text: "沙河口区" },
          { value: "210211", text: "甘井子区" },
          { value: "210212", text: "旅顺口区" },
          { value: "210213", text: "金州区" },
          { value: "210214", text: "普兰店区" },
          { value: "210224", text: "长海县" },
          { value: "210281", text: "瓦房店市" },
          { value: "210283", text: "庄河市" },
        ],
      },
      {
        value: "2103",
        text: "鞍山市",
        children: [
          { value: "210302", text: "铁东区" },
          { value: "210303", text: "铁西区" },
          { value: "210304", text: "立山区" },
          { value: "210311", text: "千山区" },
          { value: "210321", text: "台安县" },
          { value: "210323", text: "岫岩满族自治县" },
          { value: "210381", text: "海城市" },
        ],
      },
      {
        value: "2104",
        text: "抚顺市",
        children: [
          { value: "210402", text: "新抚区" },
          { value: "210403", text: "东洲区" },
          { value: "210404", text: "望花区" },
          { value: "210411", text: "顺城区" },
          { value: "210421", text: "抚顺县" },
          { value: "210422", text: "新宾满族自治县" },
          { value: "210423", text: "清原满族自治县" },
        ],
      },
      {
        value: "2105",
        text: "本溪市",
        children: [
          { value: "210502", text: "平山区" },
          { value: "210503", text: "溪湖区" },
          { value: "210504", text: "明山区" },
          { value: "210505", text: "南芬区" },
          { value: "210521", text: "本溪满族自治县" },
          { value: "210522", text: "桓仁满族自治县" },
        ],
      },
      {
        value: "2106",
        text: "丹东市",
        children: [
          { value: "210602", text: "元宝区" },
          { value: "210603", text: "振兴区" },
          { value: "210604", text: "振安区" },
          { value: "210624", text: "宽甸满族自治县" },
          { value: "210681", text: "东港市" },
          { value: "210682", text: "凤城市" },
        ],
      },
      {
        value: "2107",
        text: "锦州市",
        children: [
          { value: "210702", text: "古塔区" },
          { value: "210703", text: "凌河区" },
          { value: "210711", text: "太和区" },
          { value: "210726", text: "黑山县" },
          { value: "210727", text: "义县" },
          { value: "210781", text: "凌海市" },
          { value: "210782", text: "北镇市" },
        ],
      },
      {
        value: "2108",
        text: "营口市",
        children: [
          { value: "210802", text: "站前区" },
          { value: "210803", text: "西市区" },
          { value: "210804", text: "鲅鱼圈区" },
          { value: "210811", text: "老边区" },
          { value: "210881", text: "盖州市" },
          { value: "210882", text: "大石桥市" },
        ],
      },
      {
        value: "2109",
        text: "阜新市",
        children: [
          { value: "210902", text: "海州区" },
          { value: "210903", text: "新邱区" },
          { value: "210904", text: "太平区" },
          { value: "210905", text: "清河门区" },
          { value: "210911", text: "细河区" },
          { value: "210921", text: "阜新蒙古族自治县" },
          { value: "210922", text: "彰武县" },
        ],
      },
      {
        value: "2110",
        text: "辽阳市",
        children: [
          { value: "211002", text: "白塔区" },
          { value: "211003", text: "文圣区" },
          { value: "211004", text: "宏伟区" },
          { value: "211005", text: "弓长岭区" },
          { value: "211011", text: "太子河区" },
          { value: "211021", text: "辽阳县" },
          { value: "211081", text: "灯塔市" },
        ],
      },
      {
        value: "2111",
        text: "盘锦市",
        children: [
          { value: "211102", text: "双台子区" },
          { value: "211103", text: "兴隆台区" },
          { value: "211104", text: "大洼区" },
          { value: "211122", text: "盘山县" },
        ],
      },
      {
        value: "2112",
        text: "铁岭市",
        children: [
          { value: "211202", text: "银州区" },
          { value: "211204", text: "清河区" },
          { value: "211221", text: "铁岭县" },
          { value: "211223", text: "西丰县" },
          { value: "211224", text: "昌图县" },
          { value: "211281", text: "调兵山市" },
          { value: "211282", text: "开原市" },
        ],
      },
      {
        value: "2113",
        text: "朝阳市",
        children: [
          { value: "211302", text: "双塔区" },
          { value: "211303", text: "龙城区" },
          { value: "211321", text: "朝阳县" },
          { value: "211322", text: "建平县" },
          { value: "211324", text: "喀喇沁左翼蒙古族自治县" },
          { value: "211381", text: "北票市" },
          { value: "211382", text: "凌源市" },
        ],
      },
      {
        value: "2114",
        text: "葫芦岛市",
        children: [
          { value: "211402", text: "连山区" },
          { value: "211403", text: "龙港区" },
          { value: "211404", text: "南票区" },
          { value: "211421", text: "绥中县" },
          { value: "211422", text: "建昌县" },
          { value: "211481", text: "兴城市" },
        ],
      },
    ],
  },
  {
    value: "22",
    text: "吉林省",
    children: [
      {
        value: "2201",
        text: "长春市",
        children: [
          { value: "220102", text: "南关区" },
          { value: "220103", text: "宽城区" },
          { value: "220104", text: "朝阳区" },
          { value: "220105", text: "二道区" },
          { value: "220106", text: "绿园区" },
          { value: "220112", text: "双阳区" },
          { value: "220113", text: "九台区" },
          { value: "220122", text: "农安县" },
          { value: "220171", text: "长春经济技术开发区" },
          { value: "220172", text: "长春净月高新技术产业开发区" },
          { value: "220173", text: "长春高新技术产业开发区" },
          { value: "220174", text: "长春汽车经济技术开发区" },
          { value: "220182", text: "榆树市" },
          { value: "220183", text: "德惠市" },
        ],
      },
      {
        value: "2202",
        text: "吉林市",
        children: [
          { value: "220202", text: "昌邑区" },
          { value: "220203", text: "龙潭区" },
          { value: "220204", text: "船营区" },
          { value: "220211", text: "丰满区" },
          { value: "220221", text: "永吉县" },
          { value: "220271", text: "吉林经济开发区" },
          { value: "220272", text: "吉林高新技术产业开发区" },
          { value: "220273", text: "吉林中国新加坡食品区" },
          { value: "220281", text: "蛟河市" },
          { value: "220282", text: "桦甸市" },
          { value: "220283", text: "舒兰市" },
          { value: "220284", text: "磐石市" },
        ],
      },
      {
        value: "2203",
        text: "四平市",
        children: [
          { value: "220302", text: "铁西区" },
          { value: "220303", text: "铁东区" },
          { value: "220322", text: "梨树县" },
          { value: "220323", text: "伊通满族自治县" },
          { value: "220381", text: "公主岭市" },
          { value: "220382", text: "双辽市" },
        ],
      },
      {
        value: "2204",
        text: "辽源市",
        children: [
          { value: "220402", text: "龙山区" },
          { value: "220403", text: "西安区" },
          { value: "220421", text: "东丰县" },
          { value: "220422", text: "东辽县" },
        ],
      },
      {
        value: "2205",
        text: "通化市",
        children: [
          { value: "220502", text: "东昌区" },
          { value: "220503", text: "二道江区" },
          { value: "220521", text: "通化县" },
          { value: "220523", text: "辉南县" },
          { value: "220524", text: "柳河县" },
          { value: "220581", text: "梅河口市" },
          { value: "220582", text: "集安市" },
        ],
      },
      {
        value: "2206",
        text: "白山市",
        children: [
          { value: "220602", text: "浑江区" },
          { value: "220605", text: "江源区" },
          { value: "220621", text: "抚松县" },
          { value: "220622", text: "靖宇县" },
          { value: "220623", text: "长白朝鲜族自治县" },
          { value: "220681", text: "临江市" },
        ],
      },
      {
        value: "2207",
        text: "松原市",
        children: [
          { value: "220702", text: "宁江区" },
          { value: "220721", text: "前郭尔罗斯蒙古族自治县" },
          { value: "220722", text: "长岭县" },
          { value: "220723", text: "乾安县" },
          { value: "220771", text: "吉林松原经济开发区" },
          { value: "220781", text: "扶余市" },
        ],
      },
      {
        value: "2208",
        text: "白城市",
        children: [
          { value: "220802", text: "洮北区" },
          { value: "220821", text: "镇赉县" },
          { value: "220822", text: "通榆县" },
          { value: "220871", text: "吉林白城经济开发区" },
          { value: "220881", text: "洮南市" },
          { value: "220882", text: "大安市" },
        ],
      },
      {
        value: "2224",
        text: "延边朝鲜族自治州",
        children: [
          { value: "222401", text: "延吉市" },
          { value: "222402", text: "图们市" },
          { value: "222403", text: "敦化市" },
          { value: "222404", text: "珲春市" },
          { value: "222405", text: "龙井市" },
          { value: "222406", text: "和龙市" },
          { value: "222424", text: "汪清县" },
          { value: "222426", text: "安图县" },
        ],
      },
    ],
  },
  {
    value: "23",
    text: "黑龙江省",
    children: [
      {
        value: "2301",
        text: "哈尔滨市",
        children: [
          { value: "230102", text: "道里区" },
          { value: "230103", text: "南岗区" },
          { value: "230104", text: "道外区" },
          { value: "230108", text: "平房区" },
          { value: "230109", text: "松北区" },
          { value: "230110", text: "香坊区" },
          { value: "230111", text: "呼兰区" },
          { value: "230112", text: "阿城区" },
          { value: "230113", text: "双城区" },
          { value: "230123", text: "依兰县" },
          { value: "230124", text: "方正县" },
          { value: "230125", text: "宾县" },
          { value: "230126", text: "巴彦县" },
          { value: "230127", text: "木兰县" },
          { value: "230128", text: "通河县" },
          { value: "230129", text: "延寿县" },
          { value: "230183", text: "尚志市" },
          { value: "230184", text: "五常市" },
        ],
      },
      {
        value: "2302",
        text: "齐齐哈尔市",
        children: [
          { value: "230202", text: "龙沙区" },
          { value: "230203", text: "建华区" },
          { value: "230204", text: "铁锋区" },
          { value: "230205", text: "昂昂溪区" },
          { value: "230206", text: "富拉尔基区" },
          { value: "230207", text: "碾子山区" },
          { value: "230208", text: "梅里斯达斡尔族区" },
          { value: "230221", text: "龙江县" },
          { value: "230223", text: "依安县" },
          { value: "230224", text: "泰来县" },
          { value: "230225", text: "甘南县" },
          { value: "230227", text: "富裕县" },
          { value: "230229", text: "克山县" },
          { value: "230230", text: "克东县" },
          { value: "230231", text: "拜泉县" },
          { value: "230281", text: "讷河市" },
        ],
      },
      {
        value: "2303",
        text: "鸡西市",
        children: [
          { value: "230302", text: "鸡冠区" },
          { value: "230303", text: "恒山区" },
          { value: "230304", text: "滴道区" },
          { value: "230305", text: "梨树区" },
          { value: "230306", text: "城子河区" },
          { value: "230307", text: "麻山区" },
          { value: "230321", text: "鸡东县" },
          { value: "230381", text: "虎林市" },
          { value: "230382", text: "密山市" },
        ],
      },
      {
        value: "2304",
        text: "鹤岗市",
        children: [
          { value: "230402", text: "向阳区" },
          { value: "230403", text: "工农区" },
          { value: "230404", text: "南山区" },
          { value: "230405", text: "兴安区" },
          { value: "230406", text: "东山区" },
          { value: "230407", text: "兴山区" },
          { value: "230421", text: "萝北县" },
          { value: "230422", text: "绥滨县" },
        ],
      },
      {
        value: "2305",
        text: "双鸭山市",
        children: [
          { value: "230502", text: "尖山区" },
          { value: "230503", text: "岭东区" },
          { value: "230505", text: "四方台区" },
          { value: "230506", text: "宝山区" },
          { value: "230521", text: "集贤县" },
          { value: "230522", text: "友谊县" },
          { value: "230523", text: "宝清县" },
          { value: "230524", text: "饶河县" },
        ],
      },
      {
        value: "2306",
        text: "大庆市",
        children: [
          { value: "230602", text: "萨尔图区" },
          { value: "230603", text: "龙凤区" },
          { value: "230604", text: "让胡路区" },
          { value: "230605", text: "红岗区" },
          { value: "230606", text: "大同区" },
          { value: "230621", text: "肇州县" },
          { value: "230622", text: "肇源县" },
          { value: "230623", text: "林甸县" },
          { value: "230624", text: "杜尔伯特蒙古族自治县" },
          { value: "230671", text: "大庆高新技术产业开发区" },
        ],
      },
      {
        value: "2307",
        text: "伊春市",
        children: [
          { value: "230717", text: "伊美区" },
          { value: "230718", text: "乌翠区" },
          { value: "230719", text: "友好区" },
          { value: "230722", text: "嘉荫县" },
          { value: "230723", text: "汤旺县" },
          { value: "230724", text: "丰林县" },
          { value: "230725", text: "大箐山县" },
          { value: "230726", text: "南岔县" },
          { value: "230751", text: "金林区" },
          { value: "230781", text: "铁力市" },
        ],
      },
      {
        value: "2308",
        text: "佳木斯市",
        children: [
          { value: "230803", text: "向阳区" },
          { value: "230804", text: "前进区" },
          { value: "230805", text: "东风区" },
          { value: "230811", text: "郊区" },
          { value: "230822", text: "桦南县" },
          { value: "230826", text: "桦川县" },
          { value: "230828", text: "汤原县" },
          { value: "230881", text: "同江市" },
          { value: "230882", text: "富锦市" },
          { value: "230883", text: "抚远市" },
        ],
      },
      {
        value: "2309",
        text: "七台河市",
        children: [
          { value: "230902", text: "新兴区" },
          { value: "230903", text: "桃山区" },
          { value: "230904", text: "茄子河区" },
          { value: "230921", text: "勃利县" },
        ],
      },
      {
        value: "2310",
        text: "牡丹江市",
        children: [
          { value: "231002", text: "东安区" },
          { value: "231003", text: "阳明区" },
          { value: "231004", text: "爱民区" },
          { value: "231005", text: "西安区" },
          { value: "231025", text: "林口县" },
          { value: "231071", text: "牡丹江经济技术开发区" },
          { value: "231081", text: "绥芬河市" },
          { value: "231083", text: "海林市" },
          { value: "231084", text: "宁安市" },
          { value: "231085", text: "穆棱市" },
          { value: "231086", text: "东宁市" },
        ],
      },
      {
        value: "2311",
        text: "黑河市",
        children: [
          { value: "231102", text: "爱辉区" },
          { value: "231123", text: "逊克县" },
          { value: "231124", text: "孙吴县" },
          { value: "231181", text: "北安市" },
          { value: "231182", text: "五大连池市" },
          { value: "231183", text: "嫩江市" },
        ],
      },
      {
        value: "2312",
        text: "绥化市",
        children: [
          { value: "231202", text: "北林区" },
          { value: "231221", text: "望奎县" },
          { value: "231222", text: "兰西县" },
          { value: "231223", text: "青冈县" },
          { value: "231224", text: "庆安县" },
          { value: "231225", text: "明水县" },
          { value: "231226", text: "绥棱县" },
          { value: "231281", text: "安达市" },
          { value: "231282", text: "肇东市" },
          { value: "231283", text: "海伦市" },
        ],
      },
      {
        value: "2327",
        text: "大兴安岭地区",
        children: [
          { value: "232701", text: "漠河市" },
          { value: "232721", text: "呼玛县" },
          { value: "232722", text: "塔河县" },
          { value: "232761", text: "加格达奇区" },
          { value: "232762", text: "松岭区" },
          { value: "232763", text: "新林区" },
          { value: "232764", text: "呼中区" },
        ],
      },
    ],
  },
  {
    value: "31",
    text: "上海市",
    children: [
      {
        value: "3101",
        text: "市辖区",
        children: [
          { value: "310101", text: "黄浦区" },
          { value: "310104", text: "徐汇区" },
          { value: "310105", text: "长宁区" },
          { value: "310106", text: "静安区" },
          { value: "310107", text: "普陀区" },
          { value: "310109", text: "虹口区" },
          { value: "310110", text: "杨浦区" },
          { value: "310112", text: "闵行区" },
          { value: "310113", text: "宝山区" },
          { value: "310114", text: "嘉定区" },
          { value: "310115", text: "浦东新区" },
          { value: "310116", text: "金山区" },
          { value: "310117", text: "松江区" },
          { value: "310118", text: "青浦区" },
          { value: "310120", text: "奉贤区" },
          { value: "310151", text: "崇明区" },
        ],
      },
    ],
  },
  {
    value: "32",
    text: "江苏省",
    children: [
      {
        value: "3201",
        text: "南京市",
        children: [
          { value: "320102", text: "玄武区" },
          { value: "320104", text: "秦淮区" },
          { value: "320105", text: "建邺区" },
          { value: "320106", text: "鼓楼区" },
          { value: "320111", text: "浦口区" },
          { value: "320113", text: "栖霞区" },
          { value: "320114", text: "雨花台区" },
          { value: "320115", text: "江宁区" },
          { value: "320116", text: "六合区" },
          { value: "320117", text: "溧水区" },
          { value: "320118", text: "高淳区" },
        ],
      },
      {
        value: "3202",
        text: "无锡市",
        children: [
          { value: "320205", text: "锡山区" },
          { value: "320206", text: "惠山区" },
          { value: "320211", text: "滨湖区" },
          { value: "320213", text: "梁溪区" },
          { value: "320214", text: "新吴区" },
          { value: "320281", text: "江阴市" },
          { value: "320282", text: "宜兴市" },
        ],
      },
      {
        value: "3203",
        text: "徐州市",
        children: [
          { value: "320302", text: "鼓楼区" },
          { value: "320303", text: "云龙区" },
          { value: "320305", text: "贾汪区" },
          { value: "320311", text: "泉山区" },
          { value: "320312", text: "铜山区" },
          { value: "320321", text: "丰县" },
          { value: "320322", text: "沛县" },
          { value: "320324", text: "睢宁县" },
          { value: "320371", text: "徐州经济技术开发区" },
          { value: "320381", text: "新沂市" },
          { value: "320382", text: "邳州市" },
        ],
      },
      {
        value: "3204",
        text: "常州市",
        children: [
          { value: "320402", text: "天宁区" },
          { value: "320404", text: "钟楼区" },
          { value: "320411", text: "新北区" },
          { value: "320412", text: "武进区" },
          { value: "320413", text: "金坛区" },
          { value: "320481", text: "溧阳市" },
        ],
      },
      {
        value: "3205",
        text: "苏州市",
        children: [
          { value: "320505", text: "虎丘区" },
          { value: "320506", text: "吴中区" },
          { value: "320507", text: "相城区" },
          { value: "320508", text: "姑苏区" },
          { value: "320509", text: "吴江区" },
          { value: "320571", text: "苏州工业园区" },
          { value: "320581", text: "常熟市" },
          { value: "320582", text: "张家港市" },
          { value: "320583", text: "昆山市" },
          { value: "320585", text: "太仓市" },
        ],
      },
      {
        value: "3206",
        text: "南通市",
        children: [
          { value: "320602", text: "崇川区" },
          { value: "320611", text: "港闸区" },
          { value: "320612", text: "通州区" },
          { value: "320623", text: "如东县" },
          { value: "320671", text: "南通经济技术开发区" },
          { value: "320681", text: "启东市" },
          { value: "320682", text: "如皋市" },
          { value: "320684", text: "海门市" },
          { value: "320685", text: "海安市" },
        ],
      },
      {
        value: "3207",
        text: "连云港市",
        children: [
          { value: "320703", text: "连云区" },
          { value: "320706", text: "海州区" },
          { value: "320707", text: "赣榆区" },
          { value: "320722", text: "东海县" },
          { value: "320723", text: "灌云县" },
          { value: "320724", text: "灌南县" },
          { value: "320771", text: "连云港经济技术开发区" },
          { value: "320772", text: "连云港高新技术产业开发区" },
        ],
      },
      {
        value: "3208",
        text: "淮安市",
        children: [
          { value: "320803", text: "淮安区" },
          { value: "320804", text: "淮阴区" },
          { value: "320812", text: "清江浦区" },
          { value: "320813", text: "洪泽区" },
          { value: "320826", text: "涟水县" },
          { value: "320830", text: "盱眙县" },
          { value: "320831", text: "金湖县" },
          { value: "320871", text: "淮安经济技术开发区" },
        ],
      },
      {
        value: "3209",
        text: "盐城市",
        children: [
          { value: "320902", text: "亭湖区" },
          { value: "320903", text: "盐都区" },
          { value: "320904", text: "大丰区" },
          { value: "320921", text: "响水县" },
          { value: "320922", text: "滨海县" },
          { value: "320923", text: "阜宁县" },
          { value: "320924", text: "射阳县" },
          { value: "320925", text: "建湖县" },
          { value: "320971", text: "盐城经济技术开发区" },
          { value: "320981", text: "东台市" },
        ],
      },
      {
        value: "3210",
        text: "扬州市",
        children: [
          { value: "321002", text: "广陵区" },
          { value: "321003", text: "邗江区" },
          { value: "321012", text: "江都区" },
          { value: "321023", text: "宝应县" },
          { value: "321071", text: "扬州经济技术开发区" },
          { value: "321081", text: "仪征市" },
          { value: "321084", text: "高邮市" },
        ],
      },
      {
        value: "3211",
        text: "镇江市",
        children: [
          { value: "321102", text: "京口区" },
          { value: "321111", text: "润州区" },
          { value: "321112", text: "丹徒区" },
          { value: "321171", text: "镇江新区" },
          { value: "321181", text: "丹阳市" },
          { value: "321182", text: "扬中市" },
          { value: "321183", text: "句容市" },
        ],
      },
      {
        value: "3212",
        text: "泰州市",
        children: [
          { value: "321202", text: "海陵区" },
          { value: "321203", text: "高港区" },
          { value: "321204", text: "姜堰区" },
          { value: "321271", text: "泰州医药高新技术产业开发区" },
          { value: "321281", text: "兴化市" },
          { value: "321282", text: "靖江市" },
          { value: "321283", text: "泰兴市" },
        ],
      },
      {
        value: "3213",
        text: "宿迁市",
        children: [
          { value: "321302", text: "宿城区" },
          { value: "321311", text: "宿豫区" },
          { value: "321322", text: "沭阳县" },
          { value: "321323", text: "泗阳县" },
          { value: "321324", text: "泗洪县" },
          { value: "321371", text: "宿迁经济技术开发区" },
        ],
      },
    ],
  },
  {
    value: "33",
    text: "浙江省",
    children: [
      {
        value: "3301",
        text: "杭州市",
        children: [
          { value: "330102", text: "上城区" },
          { value: "330103", text: "下城区" },
          { value: "330104", text: "江干区" },
          { value: "330105", text: "拱墅区" },
          { value: "330106", text: "西湖区" },
          { value: "330108", text: "滨江区" },
          { value: "330109", text: "萧山区" },
          { value: "330110", text: "余杭区" },
          { value: "330111", text: "富阳区" },
          { value: "330112", text: "临安区" },
          { value: "330122", text: "桐庐县" },
          { value: "330127", text: "淳安县" },
          { value: "330182", text: "建德市" },
        ],
      },
      {
        value: "3302",
        text: "宁波市",
        children: [
          { value: "330203", text: "海曙区" },
          { value: "330205", text: "江北区" },
          { value: "330206", text: "北仑区" },
          { value: "330211", text: "镇海区" },
          { value: "330212", text: "鄞州区" },
          { value: "330213", text: "奉化区" },
          { value: "330225", text: "象山县" },
          { value: "330226", text: "宁海县" },
          { value: "330281", text: "余姚市" },
          { value: "330282", text: "慈溪市" },
        ],
      },
      {
        value: "3303",
        text: "温州市",
        children: [
          { value: "330302", text: "鹿城区" },
          { value: "330303", text: "龙湾区" },
          { value: "330304", text: "瓯海区" },
          { value: "330305", text: "洞头区" },
          { value: "330324", text: "永嘉县" },
          { value: "330326", text: "平阳县" },
          { value: "330327", text: "苍南县" },
          { value: "330328", text: "文成县" },
          { value: "330329", text: "泰顺县" },
          { value: "330371", text: "温州经济技术开发区" },
          { value: "330381", text: "瑞安市" },
          { value: "330382", text: "乐清市" },
          { value: "330383", text: "龙港市" },
        ],
      },
      {
        value: "3304",
        text: "嘉兴市",
        children: [
          { value: "330402", text: "南湖区" },
          { value: "330411", text: "秀洲区" },
          { value: "330421", text: "嘉善县" },
          { value: "330424", text: "海盐县" },
          { value: "330481", text: "海宁市" },
          { value: "330482", text: "平湖市" },
          { value: "330483", text: "桐乡市" },
        ],
      },
      {
        value: "3305",
        text: "湖州市",
        children: [
          { value: "330502", text: "吴兴区" },
          { value: "330503", text: "南浔区" },
          { value: "330521", text: "德清县" },
          { value: "330522", text: "长兴县" },
          { value: "330523", text: "安吉县" },
        ],
      },
      {
        value: "3306",
        text: "绍兴市",
        children: [
          { value: "330602", text: "越城区" },
          { value: "330603", text: "柯桥区" },
          { value: "330604", text: "上虞区" },
          { value: "330624", text: "新昌县" },
          { value: "330681", text: "诸暨市" },
          { value: "330683", text: "嵊州市" },
        ],
      },
      {
        value: "3307",
        text: "金华市",
        children: [
          { value: "330702", text: "婺城区" },
          { value: "330703", text: "金东区" },
          { value: "330723", text: "武义县" },
          { value: "330726", text: "浦江县" },
          { value: "330727", text: "磐安县" },
          { value: "330781", text: "兰溪市" },
          { value: "330782", text: "义乌市" },
          { value: "330783", text: "东阳市" },
          { value: "330784", text: "永康市" },
        ],
      },
      {
        value: "3308",
        text: "衢州市",
        children: [
          { value: "330802", text: "柯城区" },
          { value: "330803", text: "衢江区" },
          { value: "330822", text: "常山县" },
          { value: "330824", text: "开化县" },
          { value: "330825", text: "龙游县" },
          { value: "330881", text: "江山市" },
        ],
      },
      {
        value: "3309",
        text: "舟山市",
        children: [
          { value: "330902", text: "定海区" },
          { value: "330903", text: "普陀区" },
          { value: "330921", text: "岱山县" },
          { value: "330922", text: "嵊泗县" },
        ],
      },
      {
        value: "3310",
        text: "台州市",
        children: [
          { value: "331002", text: "椒江区" },
          { value: "331003", text: "黄岩区" },
          { value: "331004", text: "路桥区" },
          { value: "331022", text: "三门县" },
          { value: "331023", text: "天台县" },
          { value: "331024", text: "仙居县" },
          { value: "331081", text: "温岭市" },
          { value: "331082", text: "临海市" },
          { value: "331083", text: "玉环市" },
        ],
      },
      {
        value: "3311",
        text: "丽水市",
        children: [
          { value: "331102", text: "莲都区" },
          { value: "331121", text: "青田县" },
          { value: "331122", text: "缙云县" },
          { value: "331123", text: "遂昌县" },
          { value: "331124", text: "松阳县" },
          { value: "331125", text: "云和县" },
          { value: "331126", text: "庆元县" },
          { value: "331127", text: "景宁畲族自治县" },
          { value: "331181", text: "龙泉市" },
        ],
      },
    ],
  },
  {
    value: "34",
    text: "安徽省",
    children: [
      {
        value: "3401",
        text: "合肥市",
        children: [
          { value: "340102", text: "瑶海区" },
          { value: "340103", text: "庐阳区" },
          { value: "340104", text: "蜀山区" },
          { value: "340111", text: "包河区" },
          { value: "340121", text: "长丰县" },
          { value: "340122", text: "肥东县" },
          { value: "340123", text: "肥西县" },
          { value: "340124", text: "庐江县" },
          { value: "340171", text: "合肥高新技术产业开发区" },
          { value: "340172", text: "合肥经济技术开发区" },
          { value: "340173", text: "合肥新站高新技术产业开发区" },
          { value: "340181", text: "巢湖市" },
        ],
      },
      {
        value: "3402",
        text: "芜湖市",
        children: [
          { value: "340202", text: "镜湖区" },
          { value: "340203", text: "弋江区" },
          { value: "340207", text: "鸠江区" },
          { value: "340208", text: "三山区" },
          { value: "340221", text: "芜湖县" },
          { value: "340222", text: "繁昌县" },
          { value: "340223", text: "南陵县" },
          { value: "340225", text: "无为县" },
          { value: "340271", text: "芜湖经济技术开发区" },
          { value: "340272", text: "安徽芜湖长江大桥经济开发区" },
        ],
      },
      {
        value: "3403",
        text: "蚌埠市",
        children: [
          { value: "340302", text: "龙子湖区" },
          { value: "340303", text: "蚌山区" },
          { value: "340304", text: "禹会区" },
          { value: "340311", text: "淮上区" },
          { value: "340321", text: "怀远县" },
          { value: "340322", text: "五河县" },
          { value: "340323", text: "固镇县" },
          { value: "340371", text: "蚌埠市高新技术开发区" },
          { value: "340372", text: "蚌埠市经济开发区" },
        ],
      },
      {
        value: "3404",
        text: "淮南市",
        children: [
          { value: "340402", text: "大通区" },
          { value: "340403", text: "田家庵区" },
          { value: "340404", text: "谢家集区" },
          { value: "340405", text: "八公山区" },
          { value: "340406", text: "潘集区" },
          { value: "340421", text: "凤台县" },
          { value: "340422", text: "寿县" },
        ],
      },
      {
        value: "3405",
        text: "马鞍山市",
        children: [
          { value: "340503", text: "花山区" },
          { value: "340504", text: "雨山区" },
          { value: "340506", text: "博望区" },
          { value: "340521", text: "当涂县" },
          { value: "340522", text: "含山县" },
          { value: "340523", text: "和县" },
        ],
      },
      {
        value: "3406",
        text: "淮北市",
        children: [
          { value: "340602", text: "杜集区" },
          { value: "340603", text: "相山区" },
          { value: "340604", text: "烈山区" },
          { value: "340621", text: "濉溪县" },
        ],
      },
      {
        value: "3407",
        text: "铜陵市",
        children: [
          { value: "340705", text: "铜官区" },
          { value: "340706", text: "义安区" },
          { value: "340711", text: "郊区" },
          { value: "340722", text: "枞阳县" },
        ],
      },
      {
        value: "3408",
        text: "安庆市",
        children: [
          { value: "340802", text: "迎江区" },
          { value: "340803", text: "大观区" },
          { value: "340811", text: "宜秀区" },
          { value: "340822", text: "怀宁县" },
          { value: "340825", text: "太湖县" },
          { value: "340826", text: "宿松县" },
          { value: "340827", text: "望江县" },
          { value: "340828", text: "岳西县" },
          { value: "340871", text: "安徽安庆经济开发区" },
          { value: "340881", text: "桐城市" },
          { value: "340882", text: "潜山市" },
        ],
      },
      {
        value: "3410",
        text: "黄山市",
        children: [
          { value: "341002", text: "屯溪区" },
          { value: "341003", text: "黄山区" },
          { value: "341004", text: "徽州区" },
          { value: "341021", text: "歙县" },
          { value: "341022", text: "休宁县" },
          { value: "341023", text: "黟县" },
          { value: "341024", text: "祁门县" },
        ],
      },
      {
        value: "3411",
        text: "滁州市",
        children: [
          { value: "341102", text: "琅琊区" },
          { value: "341103", text: "南谯区" },
          { value: "341122", text: "来安县" },
          { value: "341124", text: "全椒县" },
          { value: "341125", text: "定远县" },
          { value: "341126", text: "凤阳县" },
          { value: "341171", text: "苏滁现代产业园" },
          { value: "341172", text: "滁州经济技术开发区" },
          { value: "341181", text: "天长市" },
          { value: "341182", text: "明光市" },
        ],
      },
      {
        value: "3412",
        text: "阜阳市",
        children: [
          { value: "341202", text: "颍州区" },
          { value: "341203", text: "颍东区" },
          { value: "341204", text: "颍泉区" },
          { value: "341221", text: "临泉县" },
          { value: "341222", text: "太和县" },
          { value: "341225", text: "阜南县" },
          { value: "341226", text: "颍上县" },
          { value: "341271", text: "阜阳合肥现代产业园区" },
          { value: "341272", text: "阜阳经济技术开发区" },
          { value: "341282", text: "界首市" },
        ],
      },
      {
        value: "3413",
        text: "宿州市",
        children: [
          { value: "341302", text: "埇桥区" },
          { value: "341321", text: "砀山县" },
          { value: "341322", text: "萧县" },
          { value: "341323", text: "灵璧县" },
          { value: "341324", text: "泗县" },
          { value: "341371", text: "宿州马鞍山现代产业园区" },
          { value: "341372", text: "宿州经济技术开发区" },
        ],
      },
      {
        value: "3415",
        text: "六安市",
        children: [
          { value: "341502", text: "金安区" },
          { value: "341503", text: "裕安区" },
          { value: "341504", text: "叶集区" },
          { value: "341522", text: "霍邱县" },
          { value: "341523", text: "舒城县" },
          { value: "341524", text: "金寨县" },
          { value: "341525", text: "霍山县" },
        ],
      },
      {
        value: "3416",
        text: "亳州市",
        children: [
          { value: "341602", text: "谯城区" },
          { value: "341621", text: "涡阳县" },
          { value: "341622", text: "蒙城县" },
          { value: "341623", text: "利辛县" },
        ],
      },
      {
        value: "3417",
        text: "池州市",
        children: [
          { value: "341702", text: "贵池区" },
          { value: "341721", text: "东至县" },
          { value: "341722", text: "石台县" },
          { value: "341723", text: "青阳县" },
        ],
      },
      {
        value: "3418",
        text: "宣城市",
        children: [
          { value: "341802", text: "宣州区" },
          { value: "341821", text: "郎溪县" },
          { value: "341823", text: "泾县" },
          { value: "341824", text: "绩溪县" },
          { value: "341825", text: "旌德县" },
          { value: "341871", text: "宣城市经济开发区" },
          { value: "341881", text: "宁国市" },
          { value: "341882", text: "广德市" },
        ],
      },
    ],
  },
  {
    value: "35",
    text: "福建省",
    children: [
      {
        value: "3501",
        text: "福州市",
        children: [
          { value: "350102", text: "鼓楼区" },
          { value: "350103", text: "台江区" },
          { value: "350104", text: "仓山区" },
          { value: "350105", text: "马尾区" },
          { value: "350111", text: "晋安区" },
          { value: "350112", text: "长乐区" },
          { value: "350121", text: "闽侯县" },
          { value: "350122", text: "连江县" },
          { value: "350123", text: "罗源县" },
          { value: "350124", text: "闽清县" },
          { value: "350125", text: "永泰县" },
          { value: "350128", text: "平潭县" },
          { value: "350181", text: "福清市" },
        ],
      },
      {
        value: "3502",
        text: "厦门市",
        children: [
          { value: "350203", text: "思明区" },
          { value: "350205", text: "海沧区" },
          { value: "350206", text: "湖里区" },
          { value: "350211", text: "集美区" },
          { value: "350212", text: "同安区" },
          { value: "350213", text: "翔安区" },
        ],
      },
      {
        value: "3503",
        text: "莆田市",
        children: [
          { value: "350302", text: "城厢区" },
          { value: "350303", text: "涵江区" },
          { value: "350304", text: "荔城区" },
          { value: "350305", text: "秀屿区" },
          { value: "350322", text: "仙游县" },
        ],
      },
      {
        value: "3504",
        text: "三明市",
        children: [
          { value: "350402", text: "梅列区" },
          { value: "350403", text: "三元区" },
          { value: "350421", text: "明溪县" },
          { value: "350423", text: "清流县" },
          { value: "350424", text: "宁化县" },
          { value: "350425", text: "大田县" },
          { value: "350426", text: "尤溪县" },
          { value: "350427", text: "沙县" },
          { value: "350428", text: "将乐县" },
          { value: "350429", text: "泰宁县" },
          { value: "350430", text: "建宁县" },
          { value: "350481", text: "永安市" },
        ],
      },
      {
        value: "3505",
        text: "泉州市",
        children: [
          { value: "350502", text: "鲤城区" },
          { value: "350503", text: "丰泽区" },
          { value: "350504", text: "洛江区" },
          { value: "350505", text: "泉港区" },
          { value: "350521", text: "惠安县" },
          { value: "350524", text: "安溪县" },
          { value: "350525", text: "永春县" },
          { value: "350526", text: "德化县" },
          { value: "350527", text: "金门县" },
          { value: "350581", text: "石狮市" },
          { value: "350582", text: "晋江市" },
          { value: "350583", text: "南安市" },
        ],
      },
      {
        value: "3506",
        text: "漳州市",
        children: [
          { value: "350602", text: "芗城区" },
          { value: "350603", text: "龙文区" },
          { value: "350622", text: "云霄县" },
          { value: "350623", text: "漳浦县" },
          { value: "350624", text: "诏安县" },
          { value: "350625", text: "长泰县" },
          { value: "350626", text: "东山县" },
          { value: "350627", text: "南靖县" },
          { value: "350628", text: "平和县" },
          { value: "350629", text: "华安县" },
          { value: "350681", text: "龙海市" },
        ],
      },
      {
        value: "3507",
        text: "南平市",
        children: [
          { value: "350702", text: "延平区" },
          { value: "350703", text: "建阳区" },
          { value: "350721", text: "顺昌县" },
          { value: "350722", text: "浦城县" },
          { value: "350723", text: "光泽县" },
          { value: "350724", text: "松溪县" },
          { value: "350725", text: "政和县" },
          { value: "350781", text: "邵武市" },
          { value: "350782", text: "武夷山市" },
          { value: "350783", text: "建瓯市" },
        ],
      },
      {
        value: "3508",
        text: "龙岩市",
        children: [
          { value: "350802", text: "新罗区" },
          { value: "350803", text: "永定区" },
          { value: "350821", text: "长汀县" },
          { value: "350823", text: "上杭县" },
          { value: "350824", text: "武平县" },
          { value: "350825", text: "连城县" },
          { value: "350881", text: "漳平市" },
        ],
      },
      {
        value: "3509",
        text: "宁德市",
        children: [
          { value: "350902", text: "蕉城区" },
          { value: "350921", text: "霞浦县" },
          { value: "350922", text: "古田县" },
          { value: "350923", text: "屏南县" },
          { value: "350924", text: "寿宁县" },
          { value: "350925", text: "周宁县" },
          { value: "350926", text: "柘荣县" },
          { value: "350981", text: "福安市" },
          { value: "350982", text: "福鼎市" },
        ],
      },
    ],
  },
  {
    value: "36",
    text: "江西省",
    children: [
      {
        value: "3601",
        text: "南昌市",
        children: [
          { value: "360102", text: "东湖区" },
          { value: "360103", text: "西湖区" },
          { value: "360104", text: "青云谱区" },
          { value: "360105", text: "湾里区" },
          { value: "360111", text: "青山湖区" },
          { value: "360112", text: "新建区" },
          { value: "360121", text: "南昌县" },
          { value: "360123", text: "安义县" },
          { value: "360124", text: "进贤县" },
        ],
      },
      {
        value: "3602",
        text: "景德镇市",
        children: [
          { value: "360202", text: "昌江区" },
          { value: "360203", text: "珠山区" },
          { value: "360222", text: "浮梁县" },
          { value: "360281", text: "乐平市" },
        ],
      },
      {
        value: "3603",
        text: "萍乡市",
        children: [
          { value: "360302", text: "安源区" },
          { value: "360313", text: "湘东区" },
          { value: "360321", text: "莲花县" },
          { value: "360322", text: "上栗县" },
          { value: "360323", text: "芦溪县" },
        ],
      },
      {
        value: "3604",
        text: "九江市",
        children: [
          { value: "360402", text: "濂溪区" },
          { value: "360403", text: "浔阳区" },
          { value: "360404", text: "柴桑区" },
          { value: "360423", text: "武宁县" },
          { value: "360424", text: "修水县" },
          { value: "360425", text: "永修县" },
          { value: "360426", text: "德安县" },
          { value: "360428", text: "都昌县" },
          { value: "360429", text: "湖口县" },
          { value: "360430", text: "彭泽县" },
          { value: "360481", text: "瑞昌市" },
          { value: "360482", text: "共青城市" },
          { value: "360483", text: "庐山市" },
        ],
      },
      {
        value: "3605",
        text: "新余市",
        children: [
          { value: "360502", text: "渝水区" },
          { value: "360521", text: "分宜县" },
        ],
      },
      {
        value: "3606",
        text: "鹰潭市",
        children: [
          { value: "360602", text: "月湖区" },
          { value: "360603", text: "余江区" },
          { value: "360681", text: "贵溪市" },
        ],
      },
      {
        value: "3607",
        text: "赣州市",
        children: [
          { value: "360702", text: "章贡区" },
          { value: "360703", text: "南康区" },
          { value: "360704", text: "赣县区" },
          { value: "360722", text: "信丰县" },
          { value: "360723", text: "大余县" },
          { value: "360724", text: "上犹县" },
          { value: "360725", text: "崇义县" },
          { value: "360726", text: "安远县" },
          { value: "360727", text: "龙南县" },
          { value: "360728", text: "定南县" },
          { value: "360729", text: "全南县" },
          { value: "360730", text: "宁都县" },
          { value: "360731", text: "于都县" },
          { value: "360732", text: "兴国县" },
          { value: "360733", text: "会昌县" },
          { value: "360734", text: "寻乌县" },
          { value: "360735", text: "石城县" },
          { value: "360781", text: "瑞金市" },
        ],
      },
      {
        value: "3608",
        text: "吉安市",
        children: [
          { value: "360802", text: "吉州区" },
          { value: "360803", text: "青原区" },
          { value: "360821", text: "吉安县" },
          { value: "360822", text: "吉水县" },
          { value: "360823", text: "峡江县" },
          { value: "360824", text: "新干县" },
          { value: "360825", text: "永丰县" },
          { value: "360826", text: "泰和县" },
          { value: "360827", text: "遂川县" },
          { value: "360828", text: "万安县" },
          { value: "360829", text: "安福县" },
          { value: "360830", text: "永新县" },
          { value: "360881", text: "井冈山市" },
        ],
      },
      {
        value: "3609",
        text: "宜春市",
        children: [
          { value: "360902", text: "袁州区" },
          { value: "360921", text: "奉新县" },
          { value: "360922", text: "万载县" },
          { value: "360923", text: "上高县" },
          { value: "360924", text: "宜丰县" },
          { value: "360925", text: "靖安县" },
          { value: "360926", text: "铜鼓县" },
          { value: "360981", text: "丰城市" },
          { value: "360982", text: "樟树市" },
          { value: "360983", text: "高安市" },
        ],
      },
      {
        value: "3610",
        text: "抚州市",
        children: [
          { value: "361002", text: "临川区" },
          { value: "361003", text: "东乡区" },
          { value: "361021", text: "南城县" },
          { value: "361022", text: "黎川县" },
          { value: "361023", text: "南丰县" },
          { value: "361024", text: "崇仁县" },
          { value: "361025", text: "乐安县" },
          { value: "361026", text: "宜黄县" },
          { value: "361027", text: "金溪县" },
          { value: "361028", text: "资溪县" },
          { value: "361030", text: "广昌县" },
        ],
      },
      {
        value: "3611",
        text: "上饶市",
        children: [
          { value: "361102", text: "信州区" },
          { value: "361103", text: "广丰区" },
          { value: "361104", text: "广信区" },
          { value: "361123", text: "玉山县" },
          { value: "361124", text: "铅山县" },
          { value: "361125", text: "横峰县" },
          { value: "361126", text: "弋阳县" },
          { value: "361127", text: "余干县" },
          { value: "361128", text: "鄱阳县" },
          { value: "361129", text: "万年县" },
          { value: "361130", text: "婺源县" },
          { value: "361181", text: "德兴市" },
        ],
      },
    ],
  },
  {
    value: "37",
    text: "山东省",
    children: [
      {
        value: "3701",
        text: "济南市",
        children: [
          { value: "370102", text: "历下区" },
          { value: "370103", text: "市中区" },
          { value: "370104", text: "槐荫区" },
          { value: "370105", text: "天桥区" },
          { value: "370112", text: "历城区" },
          { value: "370113", text: "长清区" },
          { value: "370114", text: "章丘区" },
          { value: "370115", text: "济阳区" },
          { value: "370116", text: "莱芜区" },
          { value: "370117", text: "钢城区" },
          { value: "370124", text: "平阴县" },
          { value: "370126", text: "商河县" },
          { value: "370171", text: "济南高新技术产业开发区" },
        ],
      },
      {
        value: "3702",
        text: "青岛市",
        children: [
          { value: "370202", text: "市南区" },
          { value: "370203", text: "市北区" },
          { value: "370211", text: "黄岛区" },
          { value: "370212", text: "崂山区" },
          { value: "370213", text: "李沧区" },
          { value: "370214", text: "城阳区" },
          { value: "370215", text: "即墨区" },
          { value: "370271", text: "青岛高新技术产业开发区" },
          { value: "370281", text: "胶州市" },
          { value: "370283", text: "平度市" },
          { value: "370285", text: "莱西市" },
        ],
      },
      {
        value: "3703",
        text: "淄博市",
        children: [
          { value: "370302", text: "淄川区" },
          { value: "370303", text: "张店区" },
          { value: "370304", text: "博山区" },
          { value: "370305", text: "临淄区" },
          { value: "370306", text: "周村区" },
          { value: "370321", text: "桓台县" },
          { value: "370322", text: "高青县" },
          { value: "370323", text: "沂源县" },
        ],
      },
      {
        value: "3704",
        text: "枣庄市",
        children: [
          { value: "370402", text: "市中区" },
          { value: "370403", text: "薛城区" },
          { value: "370404", text: "峄城区" },
          { value: "370405", text: "台儿庄区" },
          { value: "370406", text: "山亭区" },
          { value: "370481", text: "滕州市" },
        ],
      },
      {
        value: "3705",
        text: "东营市",
        children: [
          { value: "370502", text: "东营区" },
          { value: "370503", text: "河口区" },
          { value: "370505", text: "垦利区" },
          { value: "370522", text: "利津县" },
          { value: "370523", text: "广饶县" },
          { value: "370571", text: "东营经济技术开发区" },
          { value: "370572", text: "东营港经济开发区" },
        ],
      },
      {
        value: "3706",
        text: "烟台市",
        children: [
          { value: "370602", text: "芝罘区" },
          { value: "370611", text: "福山区" },
          { value: "370612", text: "牟平区" },
          { value: "370613", text: "莱山区" },
          { value: "370634", text: "长岛县" },
          { value: "370671", text: "烟台高新技术产业开发区" },
          { value: "370672", text: "烟台经济技术开发区" },
          { value: "370681", text: "龙口市" },
          { value: "370682", text: "莱阳市" },
          { value: "370683", text: "莱州市" },
          { value: "370684", text: "蓬莱市" },
          { value: "370685", text: "招远市" },
          { value: "370686", text: "栖霞市" },
          { value: "370687", text: "海阳市" },
        ],
      },
      {
        value: "3707",
        text: "潍坊市",
        children: [
          { value: "370702", text: "潍城区" },
          { value: "370703", text: "寒亭区" },
          { value: "370704", text: "坊子区" },
          { value: "370705", text: "奎文区" },
          { value: "370724", text: "临朐县" },
          { value: "370725", text: "昌乐县" },
          { value: "370772", text: "潍坊滨海经济技术开发区" },
          { value: "370781", text: "青州市" },
          { value: "370782", text: "诸城市" },
          { value: "370783", text: "寿光市" },
          { value: "370784", text: "安丘市" },
          { value: "370785", text: "高密市" },
          { value: "370786", text: "昌邑市" },
        ],
      },
      {
        value: "3708",
        text: "济宁市",
        children: [
          { value: "370811", text: "任城区" },
          { value: "370812", text: "兖州区" },
          { value: "370826", text: "微山县" },
          { value: "370827", text: "鱼台县" },
          { value: "370828", text: "金乡县" },
          { value: "370829", text: "嘉祥县" },
          { value: "370830", text: "汶上县" },
          { value: "370831", text: "泗水县" },
          { value: "370832", text: "梁山县" },
          { value: "370871", text: "济宁高新技术产业开发区" },
          { value: "370881", text: "曲阜市" },
          { value: "370883", text: "邹城市" },
        ],
      },
      {
        value: "3709",
        text: "泰安市",
        children: [
          { value: "370902", text: "泰山区" },
          { value: "370911", text: "岱岳区" },
          { value: "370921", text: "宁阳县" },
          { value: "370923", text: "东平县" },
          { value: "370982", text: "新泰市" },
          { value: "370983", text: "肥城市" },
        ],
      },
      {
        value: "3710",
        text: "威海市",
        children: [
          { value: "371002", text: "环翠区" },
          { value: "371003", text: "文登区" },
          { value: "371071", text: "威海火炬高技术产业开发区" },
          { value: "371072", text: "威海经济技术开发区" },
          { value: "371073", text: "威海临港经济技术开发区" },
          { value: "371082", text: "荣成市" },
          { value: "371083", text: "乳山市" },
        ],
      },
      {
        value: "3711",
        text: "日照市",
        children: [
          { value: "371102", text: "东港区" },
          { value: "371103", text: "岚山区" },
          { value: "371121", text: "五莲县" },
          { value: "371122", text: "莒县" },
          { value: "371171", text: "日照经济技术开发区" },
        ],
      },
      {
        value: "3713",
        text: "临沂市",
        children: [
          { value: "371302", text: "兰山区" },
          { value: "371311", text: "罗庄区" },
          { value: "371312", text: "河东区" },
          { value: "371321", text: "沂南县" },
          { value: "371322", text: "郯城县" },
          { value: "371323", text: "沂水县" },
          { value: "371324", text: "兰陵县" },
          { value: "371325", text: "费县" },
          { value: "371326", text: "平邑县" },
          { value: "371327", text: "莒南县" },
          { value: "371328", text: "蒙阴县" },
          { value: "371329", text: "临沭县" },
          { value: "371371", text: "临沂高新技术产业开发区" },
          { value: "371372", text: "临沂经济技术开发区" },
          { value: "371373", text: "临沂临港经济开发区" },
        ],
      },
      {
        value: "3714",
        text: "德州市",
        children: [
          { value: "371402", text: "德城区" },
          { value: "371403", text: "陵城区" },
          { value: "371422", text: "宁津县" },
          { value: "371423", text: "庆云县" },
          { value: "371424", text: "临邑县" },
          { value: "371425", text: "齐河县" },
          { value: "371426", text: "平原县" },
          { value: "371427", text: "夏津县" },
          { value: "371428", text: "武城县" },
          { value: "371471", text: "德州经济技术开发区" },
          { value: "371472", text: "德州运河经济开发区" },
          { value: "371481", text: "乐陵市" },
          { value: "371482", text: "禹城市" },
        ],
      },
      {
        value: "3715",
        text: "聊城市",
        children: [
          { value: "371502", text: "东昌府区" },
          { value: "371503", text: "茌平区" },
          { value: "371521", text: "阳谷县" },
          { value: "371522", text: "莘县" },
          { value: "371524", text: "东阿县" },
          { value: "371525", text: "冠县" },
          { value: "371526", text: "高唐县" },
          { value: "371581", text: "临清市" },
        ],
      },
      {
        value: "3716",
        text: "滨州市",
        children: [
          { value: "371602", text: "滨城区" },
          { value: "371603", text: "沾化区" },
          { value: "371621", text: "惠民县" },
          { value: "371622", text: "阳信县" },
          { value: "371623", text: "无棣县" },
          { value: "371625", text: "博兴县" },
          { value: "371681", text: "邹平市" },
        ],
      },
      {
        value: "3717",
        text: "菏泽市",
        children: [
          { value: "371702", text: "牡丹区" },
          { value: "371703", text: "定陶区" },
          { value: "371721", text: "曹县" },
          { value: "371722", text: "单县" },
          { value: "371723", text: "成武县" },
          { value: "371724", text: "巨野县" },
          { value: "371725", text: "郓城县" },
          { value: "371726", text: "鄄城县" },
          { value: "371728", text: "东明县" },
          { value: "371771", text: "菏泽经济技术开发区" },
          { value: "371772", text: "菏泽高新技术开发区" },
        ],
      },
    ],
  },
  {
    value: "41",
    text: "河南省",
    children: [
      {
        value: "4101",
        text: "郑州市",
        children: [
          { value: "410102", text: "中原区" },
          { value: "410103", text: "二七区" },
          { value: "410104", text: "管城回族区" },
          { value: "410105", text: "金水区" },
          { value: "410106", text: "上街区" },
          { value: "410108", text: "惠济区" },
          { value: "410122", text: "中牟县" },
          { value: "410171", text: "郑州经济技术开发区" },
          { value: "410172", text: "郑州高新技术产业开发区" },
          { value: "410173", text: "郑州航空港经济综合实验区" },
          { value: "410181", text: "巩义市" },
          { value: "410182", text: "荥阳市" },
          { value: "410183", text: "新密市" },
          { value: "410184", text: "新郑市" },
          { value: "410185", text: "登封市" },
        ],
      },
      {
        value: "4102",
        text: "开封市",
        children: [
          { value: "410202", text: "龙亭区" },
          { value: "410203", text: "顺河回族区" },
          { value: "410204", text: "鼓楼区" },
          { value: "410205", text: "禹王台区" },
          { value: "410212", text: "祥符区" },
          { value: "410221", text: "杞县" },
          { value: "410222", text: "通许县" },
          { value: "410223", text: "尉氏县" },
          { value: "410225", text: "兰考县" },
        ],
      },
      {
        value: "4103",
        text: "洛阳市",
        children: [
          { value: "410302", text: "老城区" },
          { value: "410303", text: "西工区" },
          { value: "410304", text: "瀍河回族区" },
          { value: "410305", text: "涧西区" },
          { value: "410306", text: "吉利区" },
          { value: "410311", text: "洛龙区" },
          { value: "410322", text: "孟津县" },
          { value: "410323", text: "新安县" },
          { value: "410324", text: "栾川县" },
          { value: "410325", text: "嵩县" },
          { value: "410326", text: "汝阳县" },
          { value: "410327", text: "宜阳县" },
          { value: "410328", text: "洛宁县" },
          { value: "410329", text: "伊川县" },
          { value: "410371", text: "洛阳高新技术产业开发区" },
          { value: "410381", text: "偃师市" },
        ],
      },
      {
        value: "4104",
        text: "平顶山市",
        children: [
          { value: "410402", text: "新华区" },
          { value: "410403", text: "卫东区" },
          { value: "410404", text: "石龙区" },
          { value: "410411", text: "湛河区" },
          { value: "410421", text: "宝丰县" },
          { value: "410422", text: "叶县" },
          { value: "410423", text: "鲁山县" },
          { value: "410425", text: "郏县" },
          { value: "410471", text: "平顶山高新技术产业开发区" },
          { value: "410472", text: "平顶山市城乡一体化示范区" },
          { value: "410481", text: "舞钢市" },
          { value: "410482", text: "汝州市" },
        ],
      },
      {
        value: "4105",
        text: "安阳市",
        children: [
          { value: "410502", text: "文峰区" },
          { value: "410503", text: "北关区" },
          { value: "410505", text: "殷都区" },
          { value: "410506", text: "龙安区" },
          { value: "410522", text: "安阳县" },
          { value: "410523", text: "汤阴县" },
          { value: "410526", text: "滑县" },
          { value: "410527", text: "内黄县" },
          { value: "410571", text: "安阳高新技术产业开发区" },
          { value: "410581", text: "林州市" },
        ],
      },
      {
        value: "4106",
        text: "鹤壁市",
        children: [
          { value: "410602", text: "鹤山区" },
          { value: "410603", text: "山城区" },
          { value: "410611", text: "淇滨区" },
          { value: "410621", text: "浚县" },
          { value: "410622", text: "淇县" },
          { value: "410671", text: "鹤壁经济技术开发区" },
        ],
      },
      {
        value: "4107",
        text: "新乡市",
        children: [
          { value: "410702", text: "红旗区" },
          { value: "410703", text: "卫滨区" },
          { value: "410704", text: "凤泉区" },
          { value: "410711", text: "牧野区" },
          { value: "410721", text: "新乡县" },
          { value: "410724", text: "获嘉县" },
          { value: "410725", text: "原阳县" },
          { value: "410726", text: "延津县" },
          { value: "410727", text: "封丘县" },
          { value: "410771", text: "新乡高新技术产业开发区" },
          { value: "410772", text: "新乡经济技术开发区" },
          { value: "410773", text: "新乡市平原城乡一体化示范区" },
          { value: "410781", text: "卫辉市" },
          { value: "410782", text: "辉县市" },
          { value: "410783", text: "长垣市" },
        ],
      },
      {
        value: "4108",
        text: "焦作市",
        children: [
          { value: "410802", text: "解放区" },
          { value: "410803", text: "中站区" },
          { value: "410804", text: "马村区" },
          { value: "410811", text: "山阳区" },
          { value: "410821", text: "修武县" },
          { value: "410822", text: "博爱县" },
          { value: "410823", text: "武陟县" },
          { value: "410825", text: "温县" },
          { value: "410871", text: "焦作城乡一体化示范区" },
          { value: "410882", text: "沁阳市" },
          { value: "410883", text: "孟州市" },
        ],
      },
      {
        value: "4109",
        text: "濮阳市",
        children: [
          { value: "410902", text: "华龙区" },
          { value: "410922", text: "清丰县" },
          { value: "410923", text: "南乐县" },
          { value: "410926", text: "范县" },
          { value: "410927", text: "台前县" },
          { value: "410928", text: "濮阳县" },
          { value: "410971", text: "河南濮阳工业园区" },
          { value: "410972", text: "濮阳经济技术开发区" },
        ],
      },
      {
        value: "4110",
        text: "许昌市",
        children: [
          { value: "411002", text: "魏都区" },
          { value: "411003", text: "建安区" },
          { value: "411024", text: "鄢陵县" },
          { value: "411025", text: "襄城县" },
          { value: "411071", text: "许昌经济技术开发区" },
          { value: "411081", text: "禹州市" },
          { value: "411082", text: "长葛市" },
        ],
      },
      {
        value: "4111",
        text: "漯河市",
        children: [
          { value: "411102", text: "源汇区" },
          { value: "411103", text: "郾城区" },
          { value: "411104", text: "召陵区" },
          { value: "411121", text: "舞阳县" },
          { value: "411122", text: "临颍县" },
          { value: "411171", text: "漯河经济技术开发区" },
        ],
      },
      {
        value: "4112",
        text: "三门峡市",
        children: [
          { value: "411202", text: "湖滨区" },
          { value: "411203", text: "陕州区" },
          { value: "411221", text: "渑池县" },
          { value: "411224", text: "卢氏县" },
          { value: "411271", text: "河南三门峡经济开发区" },
          { value: "411281", text: "义马市" },
          { value: "411282", text: "灵宝市" },
        ],
      },
      {
        value: "4113",
        text: "南阳市",
        children: [
          { value: "411302", text: "宛城区" },
          { value: "411303", text: "卧龙区" },
          { value: "411321", text: "南召县" },
          { value: "411322", text: "方城县" },
          { value: "411323", text: "西峡县" },
          { value: "411324", text: "镇平县" },
          { value: "411325", text: "内乡县" },
          { value: "411326", text: "淅川县" },
          { value: "411327", text: "社旗县" },
          { value: "411328", text: "唐河县" },
          { value: "411329", text: "新野县" },
          { value: "411330", text: "桐柏县" },
          { value: "411371", text: "南阳高新技术产业开发区" },
          { value: "411372", text: "南阳市城乡一体化示范区" },
          { value: "411381", text: "邓州市" },
        ],
      },
      {
        value: "4114",
        text: "商丘市",
        children: [
          { value: "411402", text: "梁园区" },
          { value: "411403", text: "睢阳区" },
          { value: "411421", text: "民权县" },
          { value: "411422", text: "睢县" },
          { value: "411423", text: "宁陵县" },
          { value: "411424", text: "柘城县" },
          { value: "411425", text: "虞城县" },
          { value: "411426", text: "夏邑县" },
          { value: "411471", text: "豫东综合物流产业聚集区" },
          { value: "411472", text: "河南商丘经济开发区" },
          { value: "411481", text: "永城市" },
        ],
      },
      {
        value: "4115",
        text: "信阳市",
        children: [
          { value: "411502", text: "浉河区" },
          { value: "411503", text: "平桥区" },
          { value: "411521", text: "罗山县" },
          { value: "411522", text: "光山县" },
          { value: "411523", text: "新县" },
          { value: "411524", text: "商城县" },
          { value: "411525", text: "固始县" },
          { value: "411526", text: "潢川县" },
          { value: "411527", text: "淮滨县" },
          { value: "411528", text: "息县" },
          { value: "411571", text: "信阳高新技术产业开发区" },
        ],
      },
      {
        value: "4116",
        text: "周口市",
        children: [
          { value: "411602", text: "川汇区" },
          { value: "411603", text: "淮阳区" },
          { value: "411621", text: "扶沟县" },
          { value: "411622", text: "西华县" },
          { value: "411623", text: "商水县" },
          { value: "411624", text: "沈丘县" },
          { value: "411625", text: "郸城县" },
          { value: "411627", text: "太康县" },
          { value: "411628", text: "鹿邑县" },
          { value: "411671", text: "河南周口经济开发区" },
          { value: "411681", text: "项城市" },
        ],
      },
      {
        value: "4117",
        text: "驻马店市",
        children: [
          { value: "411702", text: "驿城区" },
          { value: "411721", text: "西平县" },
          { value: "411722", text: "上蔡县" },
          { value: "411723", text: "平舆县" },
          { value: "411724", text: "正阳县" },
          { value: "411725", text: "确山县" },
          { value: "411726", text: "泌阳县" },
          { value: "411727", text: "汝南县" },
          { value: "411728", text: "遂平县" },
          { value: "411729", text: "新蔡县" },
          { value: "411771", text: "河南驻马店经济开发区" },
        ],
      },
      {
        value: "4190",
        text: "省直辖县级行政区划",
        children: [{ value: "419001", text: "济源市" }],
      },
    ],
  },
  {
    value: "42",
    text: "湖北省",
    children: [
      {
        value: "4201",
        text: "武汉市",
        children: [
          { value: "420102", text: "江岸区" },
          { value: "420103", text: "江汉区" },
          { value: "420104", text: "硚口区" },
          { value: "420105", text: "汉阳区" },
          { value: "420106", text: "武昌区" },
          { value: "420107", text: "青山区" },
          { value: "420111", text: "洪山区" },
          { value: "420112", text: "东西湖区" },
          { value: "420113", text: "汉南区" },
          { value: "420114", text: "蔡甸区" },
          { value: "420115", text: "江夏区" },
          { value: "420116", text: "黄陂区" },
          { value: "420117", text: "新洲区" },
        ],
      },
      {
        value: "4202",
        text: "黄石市",
        children: [
          { value: "420202", text: "黄石港区" },
          { value: "420203", text: "西塞山区" },
          { value: "420204", text: "下陆区" },
          { value: "420205", text: "铁山区" },
          { value: "420222", text: "阳新县" },
          { value: "420281", text: "大冶市" },
        ],
      },
      {
        value: "4203",
        text: "十堰市",
        children: [
          { value: "420302", text: "茅箭区" },
          { value: "420303", text: "张湾区" },
          { value: "420304", text: "郧阳区" },
          { value: "420322", text: "郧西县" },
          { value: "420323", text: "竹山县" },
          { value: "420324", text: "竹溪县" },
          { value: "420325", text: "房县" },
          { value: "420381", text: "丹江口市" },
        ],
      },
      {
        value: "4205",
        text: "宜昌市",
        children: [
          { value: "420502", text: "西陵区" },
          { value: "420503", text: "伍家岗区" },
          { value: "420504", text: "点军区" },
          { value: "420505", text: "猇亭区" },
          { value: "420506", text: "夷陵区" },
          { value: "420525", text: "远安县" },
          { value: "420526", text: "兴山县" },
          { value: "420527", text: "秭归县" },
          { value: "420528", text: "长阳土家族自治县" },
          { value: "420529", text: "五峰土家族自治县" },
          { value: "420581", text: "宜都市" },
          { value: "420582", text: "当阳市" },
          { value: "420583", text: "枝江市" },
        ],
      },
      {
        value: "4206",
        text: "襄阳市",
        children: [
          { value: "420602", text: "襄城区" },
          { value: "420606", text: "樊城区" },
          { value: "420607", text: "襄州区" },
          { value: "420624", text: "南漳县" },
          { value: "420625", text: "谷城县" },
          { value: "420626", text: "保康县" },
          { value: "420682", text: "老河口市" },
          { value: "420683", text: "枣阳市" },
          { value: "420684", text: "宜城市" },
        ],
      },
      {
        value: "4207",
        text: "鄂州市",
        children: [
          { value: "420702", text: "梁子湖区" },
          { value: "420703", text: "华容区" },
          { value: "420704", text: "鄂城区" },
        ],
      },
      {
        value: "4208",
        text: "荆门市",
        children: [
          { value: "420802", text: "东宝区" },
          { value: "420804", text: "掇刀区" },
          { value: "420822", text: "沙洋县" },
          { value: "420881", text: "钟祥市" },
          { value: "420882", text: "京山市" },
        ],
      },
      {
        value: "4209",
        text: "孝感市",
        children: [
          { value: "420902", text: "孝南区" },
          { value: "420921", text: "孝昌县" },
          { value: "420922", text: "大悟县" },
          { value: "420923", text: "云梦县" },
          { value: "420981", text: "应城市" },
          { value: "420982", text: "安陆市" },
          { value: "420984", text: "汉川市" },
        ],
      },
      {
        value: "4210",
        text: "荆州市",
        children: [
          { value: "421002", text: "沙市区" },
          { value: "421003", text: "荆州区" },
          { value: "421022", text: "公安县" },
          { value: "421023", text: "监利县" },
          { value: "421024", text: "江陵县" },
          { value: "421071", text: "荆州经济技术开发区" },
          { value: "421081", text: "石首市" },
          { value: "421083", text: "洪湖市" },
          { value: "421087", text: "松滋市" },
        ],
      },
      {
        value: "4211",
        text: "黄冈市",
        children: [
          { value: "421102", text: "黄州区" },
          { value: "421121", text: "团风县" },
          { value: "421122", text: "红安县" },
          { value: "421123", text: "罗田县" },
          { value: "421124", text: "英山县" },
          { value: "421125", text: "浠水县" },
          { value: "421126", text: "蕲春县" },
          { value: "421127", text: "黄梅县" },
          { value: "421171", text: "龙感湖管理区" },
          { value: "421181", text: "麻城市" },
          { value: "421182", text: "武穴市" },
        ],
      },
      {
        value: "4212",
        text: "咸宁市",
        children: [
          { value: "421202", text: "咸安区" },
          { value: "421221", text: "嘉鱼县" },
          { value: "421222", text: "通城县" },
          { value: "421223", text: "崇阳县" },
          { value: "421224", text: "通山县" },
          { value: "421281", text: "赤壁市" },
        ],
      },
      {
        value: "4213",
        text: "随州市",
        children: [
          { value: "421303", text: "曾都区" },
          { value: "421321", text: "随县" },
          { value: "421381", text: "广水市" },
        ],
      },
      {
        value: "4228",
        text: "恩施土家族苗族自治州",
        children: [
          { value: "422801", text: "恩施市" },
          { value: "422802", text: "利川市" },
          { value: "422822", text: "建始县" },
          { value: "422823", text: "巴东县" },
          { value: "422825", text: "宣恩县" },
          { value: "422826", text: "咸丰县" },
          { value: "422827", text: "来凤县" },
          { value: "422828", text: "鹤峰县" },
        ],
      },
      {
        value: "4290",
        text: "省直辖县级行政区划",
        children: [
          { value: "429004", text: "仙桃市" },
          { value: "429005", text: "潜江市" },
          { value: "429006", text: "天门市" },
          { value: "429021", text: "神农架林区" },
        ],
      },
    ],
  },
  {
    value: "43",
    text: "湖南省",
    children: [
      {
        value: "4301",
        text: "长沙市",
        children: [
          { value: "430102", text: "芙蓉区" },
          { value: "430103", text: "天心区" },
          { value: "430104", text: "岳麓区" },
          { value: "430105", text: "开福区" },
          { value: "430111", text: "雨花区" },
          { value: "430112", text: "望城区" },
          { value: "430121", text: "长沙县" },
          { value: "430181", text: "浏阳市" },
          { value: "430182", text: "宁乡市" },
        ],
      },
      {
        value: "4302",
        text: "株洲市",
        children: [
          { value: "430202", text: "荷塘区" },
          { value: "430203", text: "芦淞区" },
          { value: "430204", text: "石峰区" },
          { value: "430211", text: "天元区" },
          { value: "430212", text: "渌口区" },
          { value: "430223", text: "攸县" },
          { value: "430224", text: "茶陵县" },
          { value: "430225", text: "炎陵县" },
          { value: "430271", text: "云龙示范区" },
          { value: "430281", text: "醴陵市" },
        ],
      },
      {
        value: "4303",
        text: "湘潭市",
        children: [
          { value: "430302", text: "雨湖区" },
          { value: "430304", text: "岳塘区" },
          { value: "430321", text: "湘潭县" },
          { value: "430371", text: "湖南湘潭高新技术产业园区" },
          { value: "430372", text: "湘潭昭山示范区" },
          { value: "430373", text: "湘潭九华示范区" },
          { value: "430381", text: "湘乡市" },
          { value: "430382", text: "韶山市" },
        ],
      },
      {
        value: "4304",
        text: "衡阳市",
        children: [
          { value: "430405", text: "珠晖区" },
          { value: "430406", text: "雁峰区" },
          { value: "430407", text: "石鼓区" },
          { value: "430408", text: "蒸湘区" },
          { value: "430412", text: "南岳区" },
          { value: "430421", text: "衡阳县" },
          { value: "430422", text: "衡南县" },
          { value: "430423", text: "衡山县" },
          { value: "430424", text: "衡东县" },
          { value: "430426", text: "祁东县" },
          { value: "430471", text: "衡阳综合保税区" },
          { value: "430472", text: "湖南衡阳高新技术产业园区" },
          { value: "430473", text: "湖南衡阳松木经济开发区" },
          { value: "430481", text: "耒阳市" },
          { value: "430482", text: "常宁市" },
        ],
      },
      {
        value: "4305",
        text: "邵阳市",
        children: [
          { value: "430502", text: "双清区" },
          { value: "430503", text: "大祥区" },
          { value: "430511", text: "北塔区" },
          { value: "430522", text: "新邵县" },
          { value: "430523", text: "邵阳县" },
          { value: "430524", text: "隆回县" },
          { value: "430525", text: "洞口县" },
          { value: "430527", text: "绥宁县" },
          { value: "430528", text: "新宁县" },
          { value: "430529", text: "城步苗族自治县" },
          { value: "430581", text: "武冈市" },
          { value: "430582", text: "邵东市" },
        ],
      },
      {
        value: "4306",
        text: "岳阳市",
        children: [
          { value: "430602", text: "岳阳楼区" },
          { value: "430603", text: "云溪区" },
          { value: "430611", text: "君山区" },
          { value: "430621", text: "岳阳县" },
          { value: "430623", text: "华容县" },
          { value: "430624", text: "湘阴县" },
          { value: "430626", text: "平江县" },
          { value: "430671", text: "岳阳市屈原管理区" },
          { value: "430681", text: "汨罗市" },
          { value: "430682", text: "临湘市" },
        ],
      },
      {
        value: "4307",
        text: "常德市",
        children: [
          { value: "430702", text: "武陵区" },
          { value: "430703", text: "鼎城区" },
          { value: "430721", text: "安乡县" },
          { value: "430722", text: "汉寿县" },
          { value: "430723", text: "澧县" },
          { value: "430724", text: "临澧县" },
          { value: "430725", text: "桃源县" },
          { value: "430726", text: "石门县" },
          { value: "430771", text: "常德市西洞庭管理区" },
          { value: "430781", text: "津市市" },
        ],
      },
      {
        value: "4308",
        text: "张家界市",
        children: [
          { value: "430802", text: "永定区" },
          { value: "430811", text: "武陵源区" },
          { value: "430821", text: "慈利县" },
          { value: "430822", text: "桑植县" },
        ],
      },
      {
        value: "4309",
        text: "益阳市",
        children: [
          { value: "430902", text: "资阳区" },
          { value: "430903", text: "赫山区" },
          { value: "430921", text: "南县" },
          { value: "430922", text: "桃江县" },
          { value: "430923", text: "安化县" },
          { value: "430971", text: "益阳市大通湖管理区" },
          { value: "430972", text: "湖南益阳高新技术产业园区" },
          { value: "430981", text: "沅江市" },
        ],
      },
      {
        value: "4310",
        text: "郴州市",
        children: [
          { value: "431002", text: "北湖区" },
          { value: "431003", text: "苏仙区" },
          { value: "431021", text: "桂阳县" },
          { value: "431022", text: "宜章县" },
          { value: "431023", text: "永兴县" },
          { value: "431024", text: "嘉禾县" },
          { value: "431025", text: "临武县" },
          { value: "431026", text: "汝城县" },
          { value: "431027", text: "桂东县" },
          { value: "431028", text: "安仁县" },
          { value: "431081", text: "资兴市" },
        ],
      },
      {
        value: "4311",
        text: "永州市",
        children: [
          { value: "431102", text: "零陵区" },
          { value: "431103", text: "冷水滩区" },
          { value: "431121", text: "祁阳县" },
          { value: "431122", text: "东安县" },
          { value: "431123", text: "双牌县" },
          { value: "431124", text: "道县" },
          { value: "431125", text: "江永县" },
          { value: "431126", text: "宁远县" },
          { value: "431127", text: "蓝山县" },
          { value: "431128", text: "新田县" },
          { value: "431129", text: "江华瑶族自治县" },
          { value: "431171", text: "永州经济技术开发区" },
          { value: "431172", text: "永州市金洞管理区" },
          { value: "431173", text: "永州市回龙圩管理区" },
        ],
      },
      {
        value: "4312",
        text: "怀化市",
        children: [
          { value: "431202", text: "鹤城区" },
          { value: "431221", text: "中方县" },
          { value: "431222", text: "沅陵县" },
          { value: "431223", text: "辰溪县" },
          { value: "431224", text: "溆浦县" },
          { value: "431225", text: "会同县" },
          { value: "431226", text: "麻阳苗族自治县" },
          { value: "431227", text: "新晃侗族自治县" },
          { value: "431228", text: "芷江侗族自治县" },
          { value: "431229", text: "靖州苗族侗族自治县" },
          { value: "431230", text: "通道侗族自治县" },
          { value: "431271", text: "怀化市洪江管理区" },
          { value: "431281", text: "洪江市" },
        ],
      },
      {
        value: "4313",
        text: "娄底市",
        children: [
          { value: "431302", text: "娄星区" },
          { value: "431321", text: "双峰县" },
          { value: "431322", text: "新化县" },
          { value: "431381", text: "冷水江市" },
          { value: "431382", text: "涟源市" },
        ],
      },
      {
        value: "4331",
        text: "湘西土家族苗族自治州",
        children: [
          { value: "433101", text: "吉首市" },
          { value: "433122", text: "泸溪县" },
          { value: "433123", text: "凤凰县" },
          { value: "433124", text: "花垣县" },
          { value: "433125", text: "保靖县" },
          { value: "433126", text: "古丈县" },
          { value: "433127", text: "永顺县" },
          { value: "433130", text: "龙山县" },
          { value: "433173", text: "湖南永顺经济开发区" },
        ],
      },
    ],
  },
  {
    value: "44",
    text: "广东省",
    children: [
      {
        value: "4401",
        text: "广州市",
        children: [
          { value: "440103", text: "荔湾区" },
          { value: "440104", text: "越秀区" },
          { value: "440105", text: "海珠区" },
          { value: "440106", text: "天河区" },
          { value: "440111", text: "白云区" },
          { value: "440112", text: "黄埔区" },
          { value: "440113", text: "番禺区" },
          { value: "440114", text: "花都区" },
          { value: "440115", text: "南沙区" },
          { value: "440117", text: "从化区" },
          { value: "440118", text: "增城区" },
        ],
      },
      {
        value: "4402",
        text: "韶关市",
        children: [
          { value: "440203", text: "武江区" },
          { value: "440204", text: "浈江区" },
          { value: "440205", text: "曲江区" },
          { value: "440222", text: "始兴县" },
          { value: "440224", text: "仁化县" },
          { value: "440229", text: "翁源县" },
          { value: "440232", text: "乳源瑶族自治县" },
          { value: "440233", text: "新丰县" },
          { value: "440281", text: "乐昌市" },
          { value: "440282", text: "南雄市" },
        ],
      },
      {
        value: "4403",
        text: "深圳市",
        children: [
          { value: "440303", text: "罗湖区" },
          { value: "440304", text: "福田区" },
          { value: "440305", text: "南山区" },
          { value: "440306", text: "宝安区" },
          { value: "440307", text: "龙岗区" },
          { value: "440308", text: "盐田区" },
          { value: "440309", text: "龙华区" },
          { value: "440310", text: "坪山区" },
          { value: "440311", text: "光明区" },
        ],
      },
      {
        value: "4404",
        text: "珠海市",
        children: [
          { value: "440402", text: "香洲区" },
          { value: "440403", text: "斗门区" },
          { value: "440404", text: "金湾区" },
        ],
      },
      {
        value: "4405",
        text: "汕头市",
        children: [
          { value: "440507", text: "龙湖区" },
          { value: "440511", text: "金平区" },
          { value: "440512", text: "濠江区" },
          { value: "440513", text: "潮阳区" },
          { value: "440514", text: "潮南区" },
          { value: "440515", text: "澄海区" },
          { value: "440523", text: "南澳县" },
        ],
      },
      {
        value: "4406",
        text: "佛山市",
        children: [
          { value: "440604", text: "禅城区" },
          { value: "440605", text: "南海区" },
          { value: "440606", text: "顺德区" },
          { value: "440607", text: "三水区" },
          { value: "440608", text: "高明区" },
        ],
      },
      {
        value: "4407",
        text: "江门市",
        children: [
          { value: "440703", text: "蓬江区" },
          { value: "440704", text: "江海区" },
          { value: "440705", text: "新会区" },
          { value: "440781", text: "台山市" },
          { value: "440783", text: "开平市" },
          { value: "440784", text: "鹤山市" },
          { value: "440785", text: "恩平市" },
        ],
      },
      {
        value: "4408",
        text: "湛江市",
        children: [
          { value: "440802", text: "赤坎区" },
          { value: "440803", text: "霞山区" },
          { value: "440804", text: "坡头区" },
          { value: "440811", text: "麻章区" },
          { value: "440823", text: "遂溪县" },
          { value: "440825", text: "徐闻县" },
          { value: "440881", text: "廉江市" },
          { value: "440882", text: "雷州市" },
          { value: "440883", text: "吴川市" },
        ],
      },
      {
        value: "4409",
        text: "茂名市",
        children: [
          { value: "440902", text: "茂南区" },
          { value: "440904", text: "电白区" },
          { value: "440981", text: "高州市" },
          { value: "440982", text: "化州市" },
          { value: "440983", text: "信宜市" },
        ],
      },
      {
        value: "4412",
        text: "肇庆市",
        children: [
          { value: "441202", text: "端州区" },
          { value: "441203", text: "鼎湖区" },
          { value: "441204", text: "高要区" },
          { value: "441223", text: "广宁县" },
          { value: "441224", text: "怀集县" },
          { value: "441225", text: "封开县" },
          { value: "441226", text: "德庆县" },
          { value: "441284", text: "四会市" },
        ],
      },
      {
        value: "4413",
        text: "惠州市",
        children: [
          { value: "441302", text: "惠城区" },
          { value: "441303", text: "惠阳区" },
          { value: "441322", text: "博罗县" },
          { value: "441323", text: "惠东县" },
          { value: "441324", text: "龙门县" },
        ],
      },
      {
        value: "4414",
        text: "梅州市",
        children: [
          { value: "441402", text: "梅江区" },
          { value: "441403", text: "梅县区" },
          { value: "441422", text: "大埔县" },
          { value: "441423", text: "丰顺县" },
          { value: "441424", text: "五华县" },
          { value: "441426", text: "平远县" },
          { value: "441427", text: "蕉岭县" },
          { value: "441481", text: "兴宁市" },
        ],
      },
      {
        value: "4415",
        text: "汕尾市",
        children: [
          { value: "441502", text: "城区" },
          { value: "441521", text: "海丰县" },
          { value: "441523", text: "陆河县" },
          { value: "441581", text: "陆丰市" },
        ],
      },
      {
        value: "4416",
        text: "河源市",
        children: [
          { value: "441602", text: "源城区" },
          { value: "441621", text: "紫金县" },
          { value: "441622", text: "龙川县" },
          { value: "441623", text: "连平县" },
          { value: "441624", text: "和平县" },
          { value: "441625", text: "东源县" },
        ],
      },
      {
        value: "4417",
        text: "阳江市",
        children: [
          { value: "441702", text: "江城区" },
          { value: "441704", text: "阳东区" },
          { value: "441721", text: "阳西县" },
          { value: "441781", text: "阳春市" },
        ],
      },
      {
        value: "4418",
        text: "清远市",
        children: [
          { value: "441802", text: "清城区" },
          { value: "441803", text: "清新区" },
          { value: "441821", text: "佛冈县" },
          { value: "441823", text: "阳山县" },
          { value: "441825", text: "连山壮族瑶族自治县" },
          { value: "441826", text: "连南瑶族自治县" },
          { value: "441881", text: "英德市" },
          { value: "441882", text: "连州市" },
        ],
      },
      {
        value: "4419",
        text: "东莞市",
        children: [
          { value: "441900003", text: "东城街道" },
          { value: "441900004", text: "南城街道" },
          { value: "441900005", text: "万江街道" },
          { value: "441900006", text: "莞城街道" },
          { value: "441900101", text: "石碣镇" },
          { value: "441900102", text: "石龙镇" },
          { value: "441900103", text: "茶山镇" },
          { value: "441900104", text: "石排镇" },
          { value: "441900105", text: "企石镇" },
          { value: "441900106", text: "横沥镇" },
          { value: "441900107", text: "桥头镇" },
          { value: "441900108", text: "谢岗镇" },
          { value: "441900109", text: "东坑镇" },
          { value: "441900110", text: "常平镇" },
          { value: "441900111", text: "寮步镇" },
          { value: "441900112", text: "樟木头镇" },
          { value: "441900113", text: "大朗镇" },
          { value: "441900114", text: "黄江镇" },
          { value: "441900115", text: "清溪镇" },
          { value: "441900116", text: "塘厦镇" },
          { value: "441900117", text: "凤岗镇" },
          { value: "441900118", text: "大岭山镇" },
          { value: "441900119", text: "长安镇" },
          { value: "441900121", text: "虎门镇" },
          { value: "441900122", text: "厚街镇" },
          { value: "441900123", text: "沙田镇" },
          { value: "441900124", text: "道滘镇" },
          { value: "441900125", text: "洪梅镇" },
          { value: "441900126", text: "麻涌镇" },
          { value: "441900127", text: "望牛墩镇" },
          { value: "441900128", text: "中堂镇" },
          { value: "441900129", text: "高埗镇" },
          { value: "441900401", text: "松山湖" },
          { value: "441900402", text: "东莞港" },
          { value: "441900403", text: "东莞生态园" },
        ],
      },
      {
        value: "4420",
        text: "中山市",
        children: [
          { value: "442000001", text: "石岐街道" },
          { value: "442000002", text: "东区街道" },
          { value: "442000003", text: "中山港街道" },
          { value: "442000004", text: "西区街道" },
          { value: "442000005", text: "南区街道" },
          { value: "442000006", text: "五桂山街道" },
          { value: "442000100", text: "小榄镇" },
          { value: "442000101", text: "黄圃镇" },
          { value: "442000102", text: "民众镇" },
          { value: "442000103", text: "东凤镇" },
          { value: "442000104", text: "东升镇" },
          { value: "442000105", text: "古镇镇" },
          { value: "442000106", text: "沙溪镇" },
          { value: "442000107", text: "坦洲镇" },
          { value: "442000108", text: "港口镇" },
          { value: "442000109", text: "三角镇" },
          { value: "442000110", text: "横栏镇" },
          { value: "442000111", text: "南头镇" },
          { value: "442000112", text: "阜沙镇" },
          { value: "442000113", text: "南朗镇" },
          { value: "442000114", text: "三乡镇" },
          { value: "442000115", text: "板芙镇" },
          { value: "442000116", text: "大涌镇" },
          { value: "442000117", text: "神湾镇" },
        ],
      },
      {
        value: "4451",
        text: "潮州市",
        children: [
          { value: "445102", text: "湘桥区" },
          { value: "445103", text: "潮安区" },
          { value: "445122", text: "饶平县" },
        ],
      },
      {
        value: "4452",
        text: "揭阳市",
        children: [
          { value: "445202", text: "榕城区" },
          { value: "445203", text: "揭东区" },
          { value: "445222", text: "揭西县" },
          { value: "445224", text: "惠来县" },
          { value: "445281", text: "普宁市" },
        ],
      },
      {
        value: "4453",
        text: "云浮市",
        children: [
          { value: "445302", text: "云城区" },
          { value: "445303", text: "云安区" },
          { value: "445321", text: "新兴县" },
          { value: "445322", text: "郁南县" },
          { value: "445381", text: "罗定市" },
        ],
      },
    ],
  },
  {
    value: "45",
    text: "广西壮族自治区",
    children: [
      {
        value: "4501",
        text: "南宁市",
        children: [
          { value: "450102", text: "兴宁区" },
          { value: "450103", text: "青秀区" },
          { value: "450105", text: "江南区" },
          { value: "450107", text: "西乡塘区" },
          { value: "450108", text: "良庆区" },
          { value: "450109", text: "邕宁区" },
          { value: "450110", text: "武鸣区" },
          { value: "450123", text: "隆安县" },
          { value: "450124", text: "马山县" },
          { value: "450125", text: "上林县" },
          { value: "450126", text: "宾阳县" },
          { value: "450127", text: "横县" },
        ],
      },
      {
        value: "4502",
        text: "柳州市",
        children: [
          { value: "450202", text: "城中区" },
          { value: "450203", text: "鱼峰区" },
          { value: "450204", text: "柳南区" },
          { value: "450205", text: "柳北区" },
          { value: "450206", text: "柳江区" },
          { value: "450222", text: "柳城县" },
          { value: "450223", text: "鹿寨县" },
          { value: "450224", text: "融安县" },
          { value: "450225", text: "融水苗族自治县" },
          { value: "450226", text: "三江侗族自治县" },
        ],
      },
      {
        value: "4503",
        text: "桂林市",
        children: [
          { value: "450302", text: "秀峰区" },
          { value: "450303", text: "叠彩区" },
          { value: "450304", text: "象山区" },
          { value: "450305", text: "七星区" },
          { value: "450311", text: "雁山区" },
          { value: "450312", text: "临桂区" },
          { value: "450321", text: "阳朔县" },
          { value: "450323", text: "灵川县" },
          { value: "450324", text: "全州县" },
          { value: "450325", text: "兴安县" },
          { value: "450326", text: "永福县" },
          { value: "450327", text: "灌阳县" },
          { value: "450328", text: "龙胜各族自治县" },
          { value: "450329", text: "资源县" },
          { value: "450330", text: "平乐县" },
          { value: "450332", text: "恭城瑶族自治县" },
          { value: "450381", text: "荔浦市" },
        ],
      },
      {
        value: "4504",
        text: "梧州市",
        children: [
          { value: "450403", text: "万秀区" },
          { value: "450405", text: "长洲区" },
          { value: "450406", text: "龙圩区" },
          { value: "450421", text: "苍梧县" },
          { value: "450422", text: "藤县" },
          { value: "450423", text: "蒙山县" },
          { value: "450481", text: "岑溪市" },
        ],
      },
      {
        value: "4505",
        text: "北海市",
        children: [
          { value: "450502", text: "海城区" },
          { value: "450503", text: "银海区" },
          { value: "450512", text: "铁山港区" },
          { value: "450521", text: "合浦县" },
        ],
      },
      {
        value: "4506",
        text: "防城港市",
        children: [
          { value: "450602", text: "港口区" },
          { value: "450603", text: "防城区" },
          { value: "450621", text: "上思县" },
          { value: "450681", text: "东兴市" },
        ],
      },
      {
        value: "4507",
        text: "钦州市",
        children: [
          { value: "450702", text: "钦南区" },
          { value: "450703", text: "钦北区" },
          { value: "450721", text: "灵山县" },
          { value: "450722", text: "浦北县" },
        ],
      },
      {
        value: "4508",
        text: "贵港市",
        children: [
          { value: "450802", text: "港北区" },
          { value: "450803", text: "港南区" },
          { value: "450804", text: "覃塘区" },
          { value: "450821", text: "平南县" },
          { value: "450881", text: "桂平市" },
        ],
      },
      {
        value: "4509",
        text: "玉林市",
        children: [
          { value: "450902", text: "玉州区" },
          { value: "450903", text: "福绵区" },
          { value: "450921", text: "容县" },
          { value: "450922", text: "陆川县" },
          { value: "450923", text: "博白县" },
          { value: "450924", text: "兴业县" },
          { value: "450981", text: "北流市" },
        ],
      },
      {
        value: "4510",
        text: "百色市",
        children: [
          { value: "451002", text: "右江区" },
          { value: "451003", text: "田阳区" },
          { value: "451022", text: "田东县" },
          { value: "451023", text: "平果县" },
          { value: "451024", text: "德保县" },
          { value: "451026", text: "那坡县" },
          { value: "451027", text: "凌云县" },
          { value: "451028", text: "乐业县" },
          { value: "451029", text: "田林县" },
          { value: "451030", text: "西林县" },
          { value: "451031", text: "隆林各族自治县" },
          { value: "451081", text: "靖西市" },
        ],
      },
      {
        value: "4511",
        text: "贺州市",
        children: [
          { value: "451102", text: "八步区" },
          { value: "451103", text: "平桂区" },
          { value: "451121", text: "昭平县" },
          { value: "451122", text: "钟山县" },
          { value: "451123", text: "富川瑶族自治县" },
        ],
      },
      {
        value: "4512",
        text: "河池市",
        children: [
          { value: "451202", text: "金城江区" },
          { value: "451203", text: "宜州区" },
          { value: "451221", text: "南丹县" },
          { value: "451222", text: "天峨县" },
          { value: "451223", text: "凤山县" },
          { value: "451224", text: "东兰县" },
          { value: "451225", text: "罗城仫佬族自治县" },
          { value: "451226", text: "环江毛南族自治县" },
          { value: "451227", text: "巴马瑶族自治县" },
          { value: "451228", text: "都安瑶族自治县" },
          { value: "451229", text: "大化瑶族自治县" },
        ],
      },
      {
        value: "4513",
        text: "来宾市",
        children: [
          { value: "451302", text: "兴宾区" },
          { value: "451321", text: "忻城县" },
          { value: "451322", text: "象州县" },
          { value: "451323", text: "武宣县" },
          { value: "451324", text: "金秀瑶族自治县" },
          { value: "451381", text: "合山市" },
        ],
      },
      {
        value: "4514",
        text: "崇左市",
        children: [
          { value: "451402", text: "江州区" },
          { value: "451421", text: "扶绥县" },
          { value: "451422", text: "宁明县" },
          { value: "451423", text: "龙州县" },
          { value: "451424", text: "大新县" },
          { value: "451425", text: "天等县" },
          { value: "451481", text: "凭祥市" },
        ],
      },
    ],
  },
  {
    value: "46",
    text: "海南省",
    children: [
      {
        value: "4601",
        text: "海口市",
        children: [
          { value: "460105", text: "秀英区" },
          { value: "460106", text: "龙华区" },
          { value: "460107", text: "琼山区" },
          { value: "460108", text: "美兰区" },
        ],
      },
      {
        value: "4602",
        text: "三亚市",
        children: [
          { value: "460202", text: "海棠区" },
          { value: "460203", text: "吉阳区" },
          { value: "460204", text: "天涯区" },
          { value: "460205", text: "崖州区" },
        ],
      },
      {
        value: "4603",
        text: "三沙市",
        children: [
          { value: "460321", text: "西沙群岛" },
          { value: "460322", text: "南沙群岛" },
          { value: "460323", text: "中沙群岛的岛礁及其海域" },
        ],
      },
      {
        value: "4604",
        text: "儋州市",
        children: [
          { value: "460400100", text: "那大镇" },
          { value: "460400101", text: "和庆镇" },
          { value: "460400102", text: "南丰镇" },
          { value: "460400103", text: "大成镇" },
          { value: "460400104", text: "雅星镇" },
          { value: "460400105", text: "兰洋镇" },
          { value: "460400106", text: "光村镇" },
          { value: "460400107", text: "木棠镇" },
          { value: "460400108", text: "海头镇" },
          { value: "460400109", text: "峨蔓镇" },
          { value: "460400111", text: "王五镇" },
          { value: "460400112", text: "白马井镇" },
          { value: "460400113", text: "中和镇" },
          { value: "460400114", text: "排浦镇" },
          { value: "460400115", text: "东成镇" },
          { value: "460400116", text: "新州镇" },
          { value: "460400499", text: "洋浦经济开发区" },
          { value: "460400500", text: "华南热作学院" },
        ],
      },
      {
        value: "4690",
        text: "省直辖县级行政区划",
        children: [
          { value: "469001", text: "五指山市" },
          { value: "469002", text: "琼海市" },
          { value: "469005", text: "文昌市" },
          { value: "469006", text: "万宁市" },
          { value: "469007", text: "东方市" },
          { value: "469021", text: "定安县" },
          { value: "469022", text: "屯昌县" },
          { value: "469023", text: "澄迈县" },
          { value: "469024", text: "临高县" },
          { value: "469025", text: "白沙黎族自治县" },
          { value: "469026", text: "昌江黎族自治县" },
          { value: "469027", text: "乐东黎族自治县" },
          { value: "469028", text: "陵水黎族自治县" },
          { value: "469029", text: "保亭黎族苗族自治县" },
          { value: "469030", text: "琼中黎族苗族自治县" },
        ],
      },
    ],
  },
  {
    value: "50",
    text: "重庆市",
    children: [
      {
        value: "5001",
        text: "市辖区",
        children: [
          { value: "500101", text: "万州区" },
          { value: "500102", text: "涪陵区" },
          { value: "500103", text: "渝中区" },
          { value: "500104", text: "大渡口区" },
          { value: "500105", text: "江北区" },
          { value: "500106", text: "沙坪坝区" },
          { value: "500107", text: "九龙坡区" },
          { value: "500108", text: "南岸区" },
          { value: "500109", text: "北碚区" },
          { value: "500110", text: "綦江区" },
          { value: "500111", text: "大足区" },
          { value: "500112", text: "渝北区" },
          { value: "500113", text: "巴南区" },
          { value: "500114", text: "黔江区" },
          { value: "500115", text: "长寿区" },
          { value: "500116", text: "江津区" },
          { value: "500117", text: "合川区" },
          { value: "500118", text: "永川区" },
          { value: "500119", text: "南川区" },
          { value: "500120", text: "璧山区" },
          { value: "500151", text: "铜梁区" },
          { value: "500152", text: "潼南区" },
          { value: "500153", text: "荣昌区" },
          { value: "500154", text: "开州区" },
          { value: "500155", text: "梁平区" },
          { value: "500156", text: "武隆区" },
        ],
      },
      {
        value: "5002",
        text: "县",
        children: [
          { value: "500229", text: "城口县" },
          { value: "500230", text: "丰都县" },
          { value: "500231", text: "垫江县" },
          { value: "500233", text: "忠县" },
          { value: "500235", text: "云阳县" },
          { value: "500236", text: "奉节县" },
          { value: "500237", text: "巫山县" },
          { value: "500238", text: "巫溪县" },
          { value: "500240", text: "石柱土家族自治县" },
          { value: "500241", text: "秀山土家族苗族自治县" },
          { value: "500242", text: "酉阳土家族苗族自治县" },
          { value: "500243", text: "彭水苗族土家族自治县" },
        ],
      },
    ],
  },
  {
    value: "51",
    text: "四川省",
    children: [
      {
        value: "5101",
        text: "成都市",
        children: [
          { value: "510104", text: "锦江区" },
          { value: "510105", text: "青羊区" },
          { value: "510106", text: "金牛区" },
          { value: "510107", text: "武侯区" },
          { value: "510108", text: "成华区" },
          { value: "510112", text: "龙泉驿区" },
          { value: "510113", text: "青白江区" },
          { value: "510114", text: "新都区" },
          { value: "510115", text: "温江区" },
          { value: "510116", text: "双流区" },
          { value: "510117", text: "郫都区" },
          { value: "510121", text: "金堂县" },
          { value: "510129", text: "大邑县" },
          { value: "510131", text: "蒲江县" },
          { value: "510132", text: "新津县" },
          { value: "510181", text: "都江堰市" },
          { value: "510182", text: "彭州市" },
          { value: "510183", text: "邛崃市" },
          { value: "510184", text: "崇州市" },
          { value: "510185", text: "简阳市" },
        ],
      },
      {
        value: "5103",
        text: "自贡市",
        children: [
          { value: "510302", text: "自流井区" },
          { value: "510303", text: "贡井区" },
          { value: "510304", text: "大安区" },
          { value: "510311", text: "沿滩区" },
          { value: "510321", text: "荣县" },
          { value: "510322", text: "富顺县" },
        ],
      },
      {
        value: "5104",
        text: "攀枝花市",
        children: [
          { value: "510402", text: "东区" },
          { value: "510403", text: "西区" },
          { value: "510411", text: "仁和区" },
          { value: "510421", text: "米易县" },
          { value: "510422", text: "盐边县" },
        ],
      },
      {
        value: "5105",
        text: "泸州市",
        children: [
          { value: "510502", text: "江阳区" },
          { value: "510503", text: "纳溪区" },
          { value: "510504", text: "龙马潭区" },
          { value: "510521", text: "泸县" },
          { value: "510522", text: "合江县" },
          { value: "510524", text: "叙永县" },
          { value: "510525", text: "古蔺县" },
        ],
      },
      {
        value: "5106",
        text: "德阳市",
        children: [
          { value: "510603", text: "旌阳区" },
          { value: "510604", text: "罗江区" },
          { value: "510623", text: "中江县" },
          { value: "510681", text: "广汉市" },
          { value: "510682", text: "什邡市" },
          { value: "510683", text: "绵竹市" },
        ],
      },
      {
        value: "5107",
        text: "绵阳市",
        children: [
          { value: "510703", text: "涪城区" },
          { value: "510704", text: "游仙区" },
          { value: "510705", text: "安州区" },
          { value: "510722", text: "三台县" },
          { value: "510723", text: "盐亭县" },
          { value: "510725", text: "梓潼县" },
          { value: "510726", text: "北川羌族自治县" },
          { value: "510727", text: "平武县" },
          { value: "510781", text: "江油市" },
        ],
      },
      {
        value: "5108",
        text: "广元市",
        children: [
          { value: "510802", text: "利州区" },
          { value: "510811", text: "昭化区" },
          { value: "510812", text: "朝天区" },
          { value: "510821", text: "旺苍县" },
          { value: "510822", text: "青川县" },
          { value: "510823", text: "剑阁县" },
          { value: "510824", text: "苍溪县" },
        ],
      },
      {
        value: "5109",
        text: "遂宁市",
        children: [
          { value: "510903", text: "船山区" },
          { value: "510904", text: "安居区" },
          { value: "510921", text: "蓬溪县" },
          { value: "510923", text: "大英县" },
          { value: "510981", text: "射洪市" },
        ],
      },
      {
        value: "5110",
        text: "内江市",
        children: [
          { value: "511002", text: "市中区" },
          { value: "511011", text: "东兴区" },
          { value: "511024", text: "威远县" },
          { value: "511025", text: "资中县" },
          { value: "511071", text: "内江经济开发区" },
          { value: "511083", text: "隆昌市" },
        ],
      },
      {
        value: "5111",
        text: "乐山市",
        children: [
          { value: "511102", text: "市中区" },
          { value: "511111", text: "沙湾区" },
          { value: "511112", text: "五通桥区" },
          { value: "511113", text: "金口河区" },
          { value: "511123", text: "犍为县" },
          { value: "511124", text: "井研县" },
          { value: "511126", text: "夹江县" },
          { value: "511129", text: "沐川县" },
          { value: "511132", text: "峨边彝族自治县" },
          { value: "511133", text: "马边彝族自治县" },
          { value: "511181", text: "峨眉山市" },
        ],
      },
      {
        value: "5113",
        text: "南充市",
        children: [
          { value: "511302", text: "顺庆区" },
          { value: "511303", text: "高坪区" },
          { value: "511304", text: "嘉陵区" },
          { value: "511321", text: "南部县" },
          { value: "511322", text: "营山县" },
          { value: "511323", text: "蓬安县" },
          { value: "511324", text: "仪陇县" },
          { value: "511325", text: "西充县" },
          { value: "511381", text: "阆中市" },
        ],
      },
      {
        value: "5114",
        text: "眉山市",
        children: [
          { value: "511402", text: "东坡区" },
          { value: "511403", text: "彭山区" },
          { value: "511421", text: "仁寿县" },
          { value: "511423", text: "洪雅县" },
          { value: "511424", text: "丹棱县" },
          { value: "511425", text: "青神县" },
        ],
      },
      {
        value: "5115",
        text: "宜宾市",
        children: [
          { value: "511502", text: "翠屏区" },
          { value: "511503", text: "南溪区" },
          { value: "511504", text: "叙州区" },
          { value: "511523", text: "江安县" },
          { value: "511524", text: "长宁县" },
          { value: "511525", text: "高县" },
          { value: "511526", text: "珙县" },
          { value: "511527", text: "筠连县" },
          { value: "511528", text: "兴文县" },
          { value: "511529", text: "屏山县" },
        ],
      },
      {
        value: "5116",
        text: "广安市",
        children: [
          { value: "511602", text: "广安区" },
          { value: "511603", text: "前锋区" },
          { value: "511621", text: "岳池县" },
          { value: "511622", text: "武胜县" },
          { value: "511623", text: "邻水县" },
          { value: "511681", text: "华蓥市" },
        ],
      },
      {
        value: "5117",
        text: "达州市",
        children: [
          { value: "511702", text: "通川区" },
          { value: "511703", text: "达川区" },
          { value: "511722", text: "宣汉县" },
          { value: "511723", text: "开江县" },
          { value: "511724", text: "大竹县" },
          { value: "511725", text: "渠县" },
          { value: "511771", text: "达州经济开发区" },
          { value: "511781", text: "万源市" },
        ],
      },
      {
        value: "5118",
        text: "雅安市",
        children: [
          { value: "511802", text: "雨城区" },
          { value: "511803", text: "名山区" },
          { value: "511822", text: "荥经县" },
          { value: "511823", text: "汉源县" },
          { value: "511824", text: "石棉县" },
          { value: "511825", text: "天全县" },
          { value: "511826", text: "芦山县" },
          { value: "511827", text: "宝兴县" },
        ],
      },
      {
        value: "5119",
        text: "巴中市",
        children: [
          { value: "511902", text: "巴州区" },
          { value: "511903", text: "恩阳区" },
          { value: "511921", text: "通江县" },
          { value: "511922", text: "南江县" },
          { value: "511923", text: "平昌县" },
          { value: "511971", text: "巴中经济开发区" },
        ],
      },
      {
        value: "5120",
        text: "资阳市",
        children: [
          { value: "512002", text: "雁江区" },
          { value: "512021", text: "安岳县" },
          { value: "512022", text: "乐至县" },
        ],
      },
      {
        value: "5132",
        text: "阿坝藏族羌族自治州",
        children: [
          { value: "513201", text: "马尔康市" },
          { value: "513221", text: "汶川县" },
          { value: "513222", text: "理县" },
          { value: "513223", text: "茂县" },
          { value: "513224", text: "松潘县" },
          { value: "513225", text: "九寨沟县" },
          { value: "513226", text: "金川县" },
          { value: "513227", text: "小金县" },
          { value: "513228", text: "黑水县" },
          { value: "513230", text: "壤塘县" },
          { value: "513231", text: "阿坝县" },
          { value: "513232", text: "若尔盖县" },
          { value: "513233", text: "红原县" },
        ],
      },
      {
        value: "5133",
        text: "甘孜藏族自治州",
        children: [
          { value: "513301", text: "康定市" },
          { value: "513322", text: "泸定县" },
          { value: "513323", text: "丹巴县" },
          { value: "513324", text: "九龙县" },
          { value: "513325", text: "雅江县" },
          { value: "513326", text: "道孚县" },
          { value: "513327", text: "炉霍县" },
          { value: "513328", text: "甘孜县" },
          { value: "513329", text: "新龙县" },
          { value: "513330", text: "德格县" },
          { value: "513331", text: "白玉县" },
          { value: "513332", text: "石渠县" },
          { value: "513333", text: "色达县" },
          { value: "513334", text: "理塘县" },
          { value: "513335", text: "巴塘县" },
          { value: "513336", text: "乡城县" },
          { value: "513337", text: "稻城县" },
          { value: "513338", text: "得荣县" },
        ],
      },
      {
        value: "5134",
        text: "凉山彝族自治州",
        children: [
          { value: "513401", text: "西昌市" },
          { value: "513422", text: "木里藏族自治县" },
          { value: "513423", text: "盐源县" },
          { value: "513424", text: "德昌县" },
          { value: "513425", text: "会理县" },
          { value: "513426", text: "会东县" },
          { value: "513427", text: "宁南县" },
          { value: "513428", text: "普格县" },
          { value: "513429", text: "布拖县" },
          { value: "513430", text: "金阳县" },
          { value: "513431", text: "昭觉县" },
          { value: "513432", text: "喜德县" },
          { value: "513433", text: "冕宁县" },
          { value: "513434", text: "越西县" },
          { value: "513435", text: "甘洛县" },
          { value: "513436", text: "美姑县" },
          { value: "513437", text: "雷波县" },
        ],
      },
    ],
  },
  {
    value: "52",
    text: "贵州省",
    children: [
      {
        value: "5201",
        text: "贵阳市",
        children: [
          { value: "520102", text: "南明区" },
          { value: "520103", text: "云岩区" },
          { value: "520111", text: "花溪区" },
          { value: "520112", text: "乌当区" },
          { value: "520113", text: "白云区" },
          { value: "520115", text: "观山湖区" },
          { value: "520121", text: "开阳县" },
          { value: "520122", text: "息烽县" },
          { value: "520123", text: "修文县" },
          { value: "520181", text: "清镇市" },
        ],
      },
      {
        value: "5202",
        text: "六盘水市",
        children: [
          { value: "520201", text: "钟山区" },
          { value: "520203", text: "六枝特区" },
          { value: "520221", text: "水城县" },
          { value: "520281", text: "盘州市" },
        ],
      },
      {
        value: "5203",
        text: "遵义市",
        children: [
          { value: "520302", text: "红花岗区" },
          { value: "520303", text: "汇川区" },
          { value: "520304", text: "播州区" },
          { value: "520322", text: "桐梓县" },
          { value: "520323", text: "绥阳县" },
          { value: "520324", text: "正安县" },
          { value: "520325", text: "道真仡佬族苗族自治县" },
          { value: "520326", text: "务川仡佬族苗族自治县" },
          { value: "520327", text: "凤冈县" },
          { value: "520328", text: "湄潭县" },
          { value: "520329", text: "余庆县" },
          { value: "520330", text: "习水县" },
          { value: "520381", text: "赤水市" },
          { value: "520382", text: "仁怀市" },
        ],
      },
      {
        value: "5204",
        text: "安顺市",
        children: [
          { value: "520402", text: "西秀区" },
          { value: "520403", text: "平坝区" },
          { value: "520422", text: "普定县" },
          { value: "520423", text: "镇宁布依族苗族自治县" },
          { value: "520424", text: "关岭布依族苗族自治县" },
          { value: "520425", text: "紫云苗族布依族自治县" },
        ],
      },
      {
        value: "5205",
        text: "毕节市",
        children: [
          { value: "520502", text: "七星关区" },
          { value: "520521", text: "大方县" },
          { value: "520522", text: "黔西县" },
          { value: "520523", text: "金沙县" },
          { value: "520524", text: "织金县" },
          { value: "520525", text: "纳雍县" },
          { value: "520526", text: "威宁彝族回族苗族自治县" },
          { value: "520527", text: "赫章县" },
        ],
      },
      {
        value: "5206",
        text: "铜仁市",
        children: [
          { value: "520602", text: "碧江区" },
          { value: "520603", text: "万山区" },
          { value: "520621", text: "江口县" },
          { value: "520622", text: "玉屏侗族自治县" },
          { value: "520623", text: "石阡县" },
          { value: "520624", text: "思南县" },
          { value: "520625", text: "印江土家族苗族自治县" },
          { value: "520626", text: "德江县" },
          { value: "520627", text: "沿河土家族自治县" },
          { value: "520628", text: "松桃苗族自治县" },
        ],
      },
      {
        value: "5223",
        text: "黔西南布依族苗族自治州",
        children: [
          { value: "522301", text: "兴义市" },
          { value: "522302", text: "兴仁市" },
          { value: "522323", text: "普安县" },
          { value: "522324", text: "晴隆县" },
          { value: "522325", text: "贞丰县" },
          { value: "522326", text: "望谟县" },
          { value: "522327", text: "册亨县" },
          { value: "522328", text: "安龙县" },
        ],
      },
      {
        value: "5226",
        text: "黔东南苗族侗族自治州",
        children: [
          { value: "522601", text: "凯里市" },
          { value: "522622", text: "黄平县" },
          { value: "522623", text: "施秉县" },
          { value: "522624", text: "三穗县" },
          { value: "522625", text: "镇远县" },
          { value: "522626", text: "岑巩县" },
          { value: "522627", text: "天柱县" },
          { value: "522628", text: "锦屏县" },
          { value: "522629", text: "剑河县" },
          { value: "522630", text: "台江县" },
          { value: "522631", text: "黎平县" },
          { value: "522632", text: "榕江县" },
          { value: "522633", text: "从江县" },
          { value: "522634", text: "雷山县" },
          { value: "522635", text: "麻江县" },
          { value: "522636", text: "丹寨县" },
        ],
      },
      {
        value: "5227",
        text: "黔南布依族苗族自治州",
        children: [
          { value: "522701", text: "都匀市" },
          { value: "522702", text: "福泉市" },
          { value: "522722", text: "荔波县" },
          { value: "522723", text: "贵定县" },
          { value: "522725", text: "瓮安县" },
          { value: "522726", text: "独山县" },
          { value: "522727", text: "平塘县" },
          { value: "522728", text: "罗甸县" },
          { value: "522729", text: "长顺县" },
          { value: "522730", text: "龙里县" },
          { value: "522731", text: "惠水县" },
          { value: "522732", text: "三都水族自治县" },
        ],
      },
    ],
  },
  {
    value: "53",
    text: "云南省",
    children: [
      {
        value: "5301",
        text: "昆明市",
        children: [
          { value: "530102", text: "五华区" },
          { value: "530103", text: "盘龙区" },
          { value: "530111", text: "官渡区" },
          { value: "530112", text: "西山区" },
          { value: "530113", text: "东川区" },
          { value: "530114", text: "呈贡区" },
          { value: "530115", text: "晋宁区" },
          { value: "530124", text: "富民县" },
          { value: "530125", text: "宜良县" },
          { value: "530126", text: "石林彝族自治县" },
          { value: "530127", text: "嵩明县" },
          { value: "530128", text: "禄劝彝族苗族自治县" },
          { value: "530129", text: "寻甸回族彝族自治县" },
          { value: "530181", text: "安宁市" },
        ],
      },
      {
        value: "5303",
        text: "曲靖市",
        children: [
          { value: "530302", text: "麒麟区" },
          { value: "530303", text: "沾益区" },
          { value: "530304", text: "马龙区" },
          { value: "530322", text: "陆良县" },
          { value: "530323", text: "师宗县" },
          { value: "530324", text: "罗平县" },
          { value: "530325", text: "富源县" },
          { value: "530326", text: "会泽县" },
          { value: "530381", text: "宣威市" },
        ],
      },
      {
        value: "5304",
        text: "玉溪市",
        children: [
          { value: "530402", text: "红塔区" },
          { value: "530403", text: "江川区" },
          { value: "530422", text: "澄江县" },
          { value: "530423", text: "通海县" },
          { value: "530424", text: "华宁县" },
          { value: "530425", text: "易门县" },
          { value: "530426", text: "峨山彝族自治县" },
          { value: "530427", text: "新平彝族傣族自治县" },
          { value: "530428", text: "元江哈尼族彝族傣族自治县" },
        ],
      },
      {
        value: "5305",
        text: "保山市",
        children: [
          { value: "530502", text: "隆阳区" },
          { value: "530521", text: "施甸县" },
          { value: "530523", text: "龙陵县" },
          { value: "530524", text: "昌宁县" },
          { value: "530581", text: "腾冲市" },
        ],
      },
      {
        value: "5306",
        text: "昭通市",
        children: [
          { value: "530602", text: "昭阳区" },
          { value: "530621", text: "鲁甸县" },
          { value: "530622", text: "巧家县" },
          { value: "530623", text: "盐津县" },
          { value: "530624", text: "大关县" },
          { value: "530625", text: "永善县" },
          { value: "530626", text: "绥江县" },
          { value: "530627", text: "镇雄县" },
          { value: "530628", text: "彝良县" },
          { value: "530629", text: "威信县" },
          { value: "530681", text: "水富市" },
        ],
      },
      {
        value: "5307",
        text: "丽江市",
        children: [
          { value: "530702", text: "古城区" },
          { value: "530721", text: "玉龙纳西族自治县" },
          { value: "530722", text: "永胜县" },
          { value: "530723", text: "华坪县" },
          { value: "530724", text: "宁蒗彝族自治县" },
        ],
      },
      {
        value: "5308",
        text: "普洱市",
        children: [
          { value: "530802", text: "思茅区" },
          { value: "530821", text: "宁洱哈尼族彝族自治县" },
          { value: "530822", text: "墨江哈尼族自治县" },
          { value: "530823", text: "景东彝族自治县" },
          { value: "530824", text: "景谷傣族彝族自治县" },
          { value: "530825", text: "镇沅彝族哈尼族拉祜族自治县" },
          { value: "530826", text: "江城哈尼族彝族自治县" },
          { value: "530827", text: "孟连傣族拉祜族佤族自治县" },
          { value: "530828", text: "澜沧拉祜族自治县" },
          { value: "530829", text: "西盟佤族自治县" },
        ],
      },
      {
        value: "5309",
        text: "临沧市",
        children: [
          { value: "530902", text: "临翔区" },
          { value: "530921", text: "凤庆县" },
          { value: "530922", text: "云县" },
          { value: "530923", text: "永德县" },
          { value: "530924", text: "镇康县" },
          { value: "530925", text: "双江拉祜族佤族布朗族傣族自治县" },
          { value: "530926", text: "耿马傣族佤族自治县" },
          { value: "530927", text: "沧源佤族自治县" },
        ],
      },
      {
        value: "5323",
        text: "楚雄彝族自治州",
        children: [
          { value: "532301", text: "楚雄市" },
          { value: "532322", text: "双柏县" },
          { value: "532323", text: "牟定县" },
          { value: "532324", text: "南华县" },
          { value: "532325", text: "姚安县" },
          { value: "532326", text: "大姚县" },
          { value: "532327", text: "永仁县" },
          { value: "532328", text: "元谋县" },
          { value: "532329", text: "武定县" },
          { value: "532331", text: "禄丰县" },
        ],
      },
      {
        value: "5325",
        text: "红河哈尼族彝族自治州",
        children: [
          { value: "532501", text: "个旧市" },
          { value: "532502", text: "开远市" },
          { value: "532503", text: "蒙自市" },
          { value: "532504", text: "弥勒市" },
          { value: "532523", text: "屏边苗族自治县" },
          { value: "532524", text: "建水县" },
          { value: "532525", text: "石屏县" },
          { value: "532527", text: "泸西县" },
          { value: "532528", text: "元阳县" },
          { value: "532529", text: "红河县" },
          { value: "532530", text: "金平苗族瑶族傣族自治县" },
          { value: "532531", text: "绿春县" },
          { value: "532532", text: "河口瑶族自治县" },
        ],
      },
      {
        value: "5326",
        text: "文山壮族苗族自治州",
        children: [
          { value: "532601", text: "文山市" },
          { value: "532622", text: "砚山县" },
          { value: "532623", text: "西畴县" },
          { value: "532624", text: "麻栗坡县" },
          { value: "532625", text: "马关县" },
          { value: "532626", text: "丘北县" },
          { value: "532627", text: "广南县" },
          { value: "532628", text: "富宁县" },
        ],
      },
      {
        value: "5328",
        text: "西双版纳傣族自治州",
        children: [
          { value: "532801", text: "景洪市" },
          { value: "532822", text: "勐海县" },
          { value: "532823", text: "勐腊县" },
        ],
      },
      {
        value: "5329",
        text: "大理白族自治州",
        children: [
          { value: "532901", text: "大理市" },
          { value: "532922", text: "漾濞彝族自治县" },
          { value: "532923", text: "祥云县" },
          { value: "532924", text: "宾川县" },
          { value: "532925", text: "弥渡县" },
          { value: "532926", text: "南涧彝族自治县" },
          { value: "532927", text: "巍山彝族回族自治县" },
          { value: "532928", text: "永平县" },
          { value: "532929", text: "云龙县" },
          { value: "532930", text: "洱源县" },
          { value: "532931", text: "剑川县" },
          { value: "532932", text: "鹤庆县" },
        ],
      },
      {
        value: "5331",
        text: "德宏傣族景颇族自治州",
        children: [
          { value: "533102", text: "瑞丽市" },
          { value: "533103", text: "芒市" },
          { value: "533122", text: "梁河县" },
          { value: "533123", text: "盈江县" },
          { value: "533124", text: "陇川县" },
        ],
      },
      {
        value: "5333",
        text: "怒江傈僳族自治州",
        children: [
          { value: "533301", text: "泸水市" },
          { value: "533323", text: "福贡县" },
          { value: "533324", text: "贡山独龙族怒族自治县" },
          { value: "533325", text: "兰坪白族普米族自治县" },
        ],
      },
      {
        value: "5334",
        text: "迪庆藏族自治州",
        children: [
          { value: "533401", text: "香格里拉市" },
          { value: "533422", text: "德钦县" },
          { value: "533423", text: "维西傈僳族自治县" },
        ],
      },
    ],
  },
  {
    value: "54",
    text: "西藏自治区",
    children: [
      {
        value: "5401",
        text: "拉萨市",
        children: [
          { value: "540102", text: "城关区" },
          { value: "540103", text: "堆龙德庆区" },
          { value: "540104", text: "达孜区" },
          { value: "540121", text: "林周县" },
          { value: "540122", text: "当雄县" },
          { value: "540123", text: "尼木县" },
          { value: "540124", text: "曲水县" },
          { value: "540127", text: "墨竹工卡县" },
          { value: "540171", text: "格尔木藏青工业园区" },
          { value: "540172", text: "拉萨经济技术开发区" },
          { value: "540173", text: "西藏文化旅游创意园区" },
          { value: "540174", text: "达孜工业园区" },
        ],
      },
      {
        value: "5402",
        text: "日喀则市",
        children: [
          { value: "540202", text: "桑珠孜区" },
          { value: "540221", text: "南木林县" },
          { value: "540222", text: "江孜县" },
          { value: "540223", text: "定日县" },
          { value: "540224", text: "萨迦县" },
          { value: "540225", text: "拉孜县" },
          { value: "540226", text: "昂仁县" },
          { value: "540227", text: "谢通门县" },
          { value: "540228", text: "白朗县" },
          { value: "540229", text: "仁布县" },
          { value: "540230", text: "康马县" },
          { value: "540231", text: "定结县" },
          { value: "540232", text: "仲巴县" },
          { value: "540233", text: "亚东县" },
          { value: "540234", text: "吉隆县" },
          { value: "540235", text: "聂拉木县" },
          { value: "540236", text: "萨嘎县" },
          { value: "540237", text: "岗巴县" },
        ],
      },
      {
        value: "5403",
        text: "昌都市",
        children: [
          { value: "540302", text: "卡若区" },
          { value: "540321", text: "江达县" },
          { value: "540322", text: "贡觉县" },
          { value: "540323", text: "类乌齐县" },
          { value: "540324", text: "丁青县" },
          { value: "540325", text: "察雅县" },
          { value: "540326", text: "八宿县" },
          { value: "540327", text: "左贡县" },
          { value: "540328", text: "芒康县" },
          { value: "540329", text: "洛隆县" },
          { value: "540330", text: "边坝县" },
        ],
      },
      {
        value: "5404",
        text: "林芝市",
        children: [
          { value: "540402", text: "巴宜区" },
          { value: "540421", text: "工布江达县" },
          { value: "540422", text: "米林县" },
          { value: "540423", text: "墨脱县" },
          { value: "540424", text: "波密县" },
          { value: "540425", text: "察隅县" },
          { value: "540426", text: "朗县" },
        ],
      },
      {
        value: "5405",
        text: "山南市",
        children: [
          { value: "540502", text: "乃东区" },
          { value: "540521", text: "扎囊县" },
          { value: "540522", text: "贡嘎县" },
          { value: "540523", text: "桑日县" },
          { value: "540524", text: "琼结县" },
          { value: "540525", text: "曲松县" },
          { value: "540526", text: "措美县" },
          { value: "540527", text: "洛扎县" },
          { value: "540528", text: "加查县" },
          { value: "540529", text: "隆子县" },
          { value: "540530", text: "错那县" },
          { value: "540531", text: "浪卡子县" },
        ],
      },
      {
        value: "5406",
        text: "那曲市",
        children: [
          { value: "540602", text: "色尼区" },
          { value: "540621", text: "嘉黎县" },
          { value: "540622", text: "比如县" },
          { value: "540623", text: "聂荣县" },
          { value: "540624", text: "安多县" },
          { value: "540625", text: "申扎县" },
          { value: "540626", text: "索县" },
          { value: "540627", text: "班戈县" },
          { value: "540628", text: "巴青县" },
          { value: "540629", text: "尼玛县" },
          { value: "540630", text: "双湖县" },
        ],
      },
      {
        value: "5425",
        text: "阿里地区",
        children: [
          { value: "542521", text: "普兰县" },
          { value: "542522", text: "札达县" },
          { value: "542523", text: "噶尔县" },
          { value: "542524", text: "日土县" },
          { value: "542525", text: "革吉县" },
          { value: "542526", text: "改则县" },
          { value: "542527", text: "措勤县" },
        ],
      },
    ],
  },
  {
    value: "61",
    text: "陕西省",
    children: [
      {
        value: "6101",
        text: "西安市",
        children: [
          { value: "610102", text: "新城区" },
          { value: "610103", text: "碑林区" },
          { value: "610104", text: "莲湖区" },
          { value: "610111", text: "灞桥区" },
          { value: "610112", text: "未央区" },
          { value: "610113", text: "雁塔区" },
          { value: "610114", text: "阎良区" },
          { value: "610115", text: "临潼区" },
          { value: "610116", text: "长安区" },
          { value: "610117", text: "高陵区" },
          { value: "610118", text: "鄠邑区" },
          { value: "610122", text: "蓝田县" },
          { value: "610124", text: "周至县" },
        ],
      },
      {
        value: "6102",
        text: "铜川市",
        children: [
          { value: "610202", text: "王益区" },
          { value: "610203", text: "印台区" },
          { value: "610204", text: "耀州区" },
          { value: "610222", text: "宜君县" },
        ],
      },
      {
        value: "6103",
        text: "宝鸡市",
        children: [
          { value: "610302", text: "渭滨区" },
          { value: "610303", text: "金台区" },
          { value: "610304", text: "陈仓区" },
          { value: "610322", text: "凤翔县" },
          { value: "610323", text: "岐山县" },
          { value: "610324", text: "扶风县" },
          { value: "610326", text: "眉县" },
          { value: "610327", text: "陇县" },
          { value: "610328", text: "千阳县" },
          { value: "610329", text: "麟游县" },
          { value: "610330", text: "凤县" },
          { value: "610331", text: "太白县" },
        ],
      },
      {
        value: "6104",
        text: "咸阳市",
        children: [
          { value: "610402", text: "秦都区" },
          { value: "610403", text: "杨陵区" },
          { value: "610404", text: "渭城区" },
          { value: "610422", text: "三原县" },
          { value: "610423", text: "泾阳县" },
          { value: "610424", text: "乾县" },
          { value: "610425", text: "礼泉县" },
          { value: "610426", text: "永寿县" },
          { value: "610428", text: "长武县" },
          { value: "610429", text: "旬邑县" },
          { value: "610430", text: "淳化县" },
          { value: "610431", text: "武功县" },
          { value: "610481", text: "兴平市" },
          { value: "610482", text: "彬州市" },
        ],
      },
      {
        value: "6105",
        text: "渭南市",
        children: [
          { value: "610502", text: "临渭区" },
          { value: "610503", text: "华州区" },
          { value: "610522", text: "潼关县" },
          { value: "610523", text: "大荔县" },
          { value: "610524", text: "合阳县" },
          { value: "610525", text: "澄城县" },
          { value: "610526", text: "蒲城县" },
          { value: "610527", text: "白水县" },
          { value: "610528", text: "富平县" },
          { value: "610581", text: "韩城市" },
          { value: "610582", text: "华阴市" },
        ],
      },
      {
        value: "6106",
        text: "延安市",
        children: [
          { value: "610602", text: "宝塔区" },
          { value: "610603", text: "安塞区" },
          { value: "610621", text: "延长县" },
          { value: "610622", text: "延川县" },
          { value: "610625", text: "志丹县" },
          { value: "610626", text: "吴起县" },
          { value: "610627", text: "甘泉县" },
          { value: "610628", text: "富县" },
          { value: "610629", text: "洛川县" },
          { value: "610630", text: "宜川县" },
          { value: "610631", text: "黄龙县" },
          { value: "610632", text: "黄陵县" },
          { value: "610681", text: "子长市" },
        ],
      },
      {
        value: "6107",
        text: "汉中市",
        children: [
          { value: "610702", text: "汉台区" },
          { value: "610703", text: "南郑区" },
          { value: "610722", text: "城固县" },
          { value: "610723", text: "洋县" },
          { value: "610724", text: "西乡县" },
          { value: "610725", text: "勉县" },
          { value: "610726", text: "宁强县" },
          { value: "610727", text: "略阳县" },
          { value: "610728", text: "镇巴县" },
          { value: "610729", text: "留坝县" },
          { value: "610730", text: "佛坪县" },
        ],
      },
      {
        value: "6108",
        text: "榆林市",
        children: [
          { value: "610802", text: "榆阳区" },
          { value: "610803", text: "横山区" },
          { value: "610822", text: "府谷县" },
          { value: "610824", text: "靖边县" },
          { value: "610825", text: "定边县" },
          { value: "610826", text: "绥德县" },
          { value: "610827", text: "米脂县" },
          { value: "610828", text: "佳县" },
          { value: "610829", text: "吴堡县" },
          { value: "610830", text: "清涧县" },
          { value: "610831", text: "子洲县" },
          { value: "610881", text: "神木市" },
        ],
      },
      {
        value: "6109",
        text: "安康市",
        children: [
          { value: "610902", text: "汉滨区" },
          { value: "610921", text: "汉阴县" },
          { value: "610922", text: "石泉县" },
          { value: "610923", text: "宁陕县" },
          { value: "610924", text: "紫阳县" },
          { value: "610925", text: "岚皋县" },
          { value: "610926", text: "平利县" },
          { value: "610927", text: "镇坪县" },
          { value: "610928", text: "旬阳县" },
          { value: "610929", text: "白河县" },
        ],
      },
      {
        value: "6110",
        text: "商洛市",
        children: [
          { value: "611002", text: "商州区" },
          { value: "611021", text: "洛南县" },
          { value: "611022", text: "丹凤县" },
          { value: "611023", text: "商南县" },
          { value: "611024", text: "山阳县" },
          { value: "611025", text: "镇安县" },
          { value: "611026", text: "柞水县" },
        ],
      },
    ],
  },
  {
    value: "62",
    text: "甘肃省",
    children: [
      {
        value: "6201",
        text: "兰州市",
        children: [
          { value: "620102", text: "城关区" },
          { value: "620103", text: "七里河区" },
          { value: "620104", text: "西固区" },
          { value: "620105", text: "安宁区" },
          { value: "620111", text: "红古区" },
          { value: "620121", text: "永登县" },
          { value: "620122", text: "皋兰县" },
          { value: "620123", text: "榆中县" },
          { value: "620171", text: "兰州新区" },
        ],
      },
      {
        value: "6202",
        text: "嘉峪关市",
        children: [
          { value: "620201100", text: "新城镇" },
          { value: "620201101", text: "峪泉镇" },
          { value: "620201102", text: "文殊镇" },
          { value: "620201401", text: "雄关区" },
          { value: "620201402", text: "镜铁区" },
          { value: "620201403", text: "长城区" },
        ],
      },
      {
        value: "6203",
        text: "金昌市",
        children: [
          { value: "620302", text: "金川区" },
          { value: "620321", text: "永昌县" },
        ],
      },
      {
        value: "6204",
        text: "白银市",
        children: [
          { value: "620402", text: "白银区" },
          { value: "620403", text: "平川区" },
          { value: "620421", text: "靖远县" },
          { value: "620422", text: "会宁县" },
          { value: "620423", text: "景泰县" },
        ],
      },
      {
        value: "6205",
        text: "天水市",
        children: [
          { value: "620502", text: "秦州区" },
          { value: "620503", text: "麦积区" },
          { value: "620521", text: "清水县" },
          { value: "620522", text: "秦安县" },
          { value: "620523", text: "甘谷县" },
          { value: "620524", text: "武山县" },
          { value: "620525", text: "张家川回族自治县" },
        ],
      },
      {
        value: "6206",
        text: "武威市",
        children: [
          { value: "620602", text: "凉州区" },
          { value: "620621", text: "民勤县" },
          { value: "620622", text: "古浪县" },
          { value: "620623", text: "天祝藏族自治县" },
        ],
      },
      {
        value: "6207",
        text: "张掖市",
        children: [
          { value: "620702", text: "甘州区" },
          { value: "620721", text: "肃南裕固族自治县" },
          { value: "620722", text: "民乐县" },
          { value: "620723", text: "临泽县" },
          { value: "620724", text: "高台县" },
          { value: "620725", text: "山丹县" },
        ],
      },
      {
        value: "6208",
        text: "平凉市",
        children: [
          { value: "620802", text: "崆峒区" },
          { value: "620821", text: "泾川县" },
          { value: "620822", text: "灵台县" },
          { value: "620823", text: "崇信县" },
          { value: "620825", text: "庄浪县" },
          { value: "620826", text: "静宁县" },
          { value: "620881", text: "华亭市" },
        ],
      },
      {
        value: "6209",
        text: "酒泉市",
        children: [
          { value: "620902", text: "肃州区" },
          { value: "620921", text: "金塔县" },
          { value: "620922", text: "瓜州县" },
          { value: "620923", text: "肃北蒙古族自治县" },
          { value: "620924", text: "阿克塞哈萨克族自治县" },
          { value: "620981", text: "玉门市" },
          { value: "620982", text: "敦煌市" },
        ],
      },
      {
        value: "6210",
        text: "庆阳市",
        children: [
          { value: "621002", text: "西峰区" },
          { value: "621021", text: "庆城县" },
          { value: "621022", text: "环县" },
          { value: "621023", text: "华池县" },
          { value: "621024", text: "合水县" },
          { value: "621025", text: "正宁县" },
          { value: "621026", text: "宁县" },
          { value: "621027", text: "镇原县" },
        ],
      },
      {
        value: "6211",
        text: "定西市",
        children: [
          { value: "621102", text: "安定区" },
          { value: "621121", text: "通渭县" },
          { value: "621122", text: "陇西县" },
          { value: "621123", text: "渭源县" },
          { value: "621124", text: "临洮县" },
          { value: "621125", text: "漳县" },
          { value: "621126", text: "岷县" },
        ],
      },
      {
        value: "6212",
        text: "陇南市",
        children: [
          { value: "621202", text: "武都区" },
          { value: "621221", text: "成县" },
          { value: "621222", text: "文县" },
          { value: "621223", text: "宕昌县" },
          { value: "621224", text: "康县" },
          { value: "621225", text: "西和县" },
          { value: "621226", text: "礼县" },
          { value: "621227", text: "徽县" },
          { value: "621228", text: "两当县" },
        ],
      },
      {
        value: "6229",
        text: "临夏回族自治州",
        children: [
          { value: "622901", text: "临夏市" },
          { value: "622921", text: "临夏县" },
          { value: "622922", text: "康乐县" },
          { value: "622923", text: "永靖县" },
          { value: "622924", text: "广河县" },
          { value: "622925", text: "和政县" },
          { value: "622926", text: "东乡族自治县" },
          { value: "622927", text: "积石山保安族东乡族撒拉族自治县" },
        ],
      },
      {
        value: "6230",
        text: "甘南藏族自治州",
        children: [
          { value: "623001", text: "合作市" },
          { value: "623021", text: "临潭县" },
          { value: "623022", text: "卓尼县" },
          { value: "623023", text: "舟曲县" },
          { value: "623024", text: "迭部县" },
          { value: "623025", text: "玛曲县" },
          { value: "623026", text: "碌曲县" },
          { value: "623027", text: "夏河县" },
        ],
      },
    ],
  },
  {
    value: "63",
    text: "青海省",
    children: [
      {
        value: "6301",
        text: "西宁市",
        children: [
          { value: "630102", text: "城东区" },
          { value: "630103", text: "城中区" },
          { value: "630104", text: "城西区" },
          { value: "630105", text: "城北区" },
          { value: "630121", text: "大通回族土族自治县" },
          { value: "630122", text: "湟中县" },
          { value: "630123", text: "湟源县" },
        ],
      },
      {
        value: "6302",
        text: "海东市",
        children: [
          { value: "630202", text: "乐都区" },
          { value: "630203", text: "平安区" },
          { value: "630222", text: "民和回族土族自治县" },
          { value: "630223", text: "互助土族自治县" },
          { value: "630224", text: "化隆回族自治县" },
          { value: "630225", text: "循化撒拉族自治县" },
        ],
      },
      {
        value: "6322",
        text: "海北藏族自治州",
        children: [
          { value: "632221", text: "门源回族自治县" },
          { value: "632222", text: "祁连县" },
          { value: "632223", text: "海晏县" },
          { value: "632224", text: "刚察县" },
        ],
      },
      {
        value: "6323",
        text: "黄南藏族自治州",
        children: [
          { value: "632321", text: "同仁县" },
          { value: "632322", text: "尖扎县" },
          { value: "632323", text: "泽库县" },
          { value: "632324", text: "河南蒙古族自治县" },
        ],
      },
      {
        value: "6325",
        text: "海南藏族自治州",
        children: [
          { value: "632521", text: "共和县" },
          { value: "632522", text: "同德县" },
          { value: "632523", text: "贵德县" },
          { value: "632524", text: "兴海县" },
          { value: "632525", text: "贵南县" },
        ],
      },
      {
        value: "6326",
        text: "果洛藏族自治州",
        children: [
          { value: "632621", text: "玛沁县" },
          { value: "632622", text: "班玛县" },
          { value: "632623", text: "甘德县" },
          { value: "632624", text: "达日县" },
          { value: "632625", text: "久治县" },
          { value: "632626", text: "玛多县" },
        ],
      },
      {
        value: "6327",
        text: "玉树藏族自治州",
        children: [
          { value: "632701", text: "玉树市" },
          { value: "632722", text: "杂多县" },
          { value: "632723", text: "称多县" },
          { value: "632724", text: "治多县" },
          { value: "632725", text: "囊谦县" },
          { value: "632726", text: "曲麻莱县" },
        ],
      },
      {
        value: "6328",
        text: "海西蒙古族藏族自治州",
        children: [
          { value: "632801", text: "格尔木市" },
          { value: "632802", text: "德令哈市" },
          { value: "632803", text: "茫崖市" },
          { value: "632821", text: "乌兰县" },
          { value: "632822", text: "都兰县" },
          { value: "632823", text: "天峻县" },
          { value: "632857", text: "大柴旦行政委员会" },
        ],
      },
    ],
  },
  {
    value: "64",
    text: "宁夏回族自治区",
    children: [
      {
        value: "6401",
        text: "银川市",
        children: [
          { value: "640104", text: "兴庆区" },
          { value: "640105", text: "西夏区" },
          { value: "640106", text: "金凤区" },
          { value: "640121", text: "永宁县" },
          { value: "640122", text: "贺兰县" },
          { value: "640181", text: "灵武市" },
        ],
      },
      {
        value: "6402",
        text: "石嘴山市",
        children: [
          { value: "640202", text: "大武口区" },
          { value: "640205", text: "惠农区" },
          { value: "640221", text: "平罗县" },
        ],
      },
      {
        value: "6403",
        text: "吴忠市",
        children: [
          { value: "640302", text: "利通区" },
          { value: "640303", text: "红寺堡区" },
          { value: "640323", text: "盐池县" },
          { value: "640324", text: "同心县" },
          { value: "640381", text: "青铜峡市" },
        ],
      },
      {
        value: "6404",
        text: "固原市",
        children: [
          { value: "640402", text: "原州区" },
          { value: "640422", text: "西吉县" },
          { value: "640423", text: "隆德县" },
          { value: "640424", text: "泾源县" },
          { value: "640425", text: "彭阳县" },
        ],
      },
      {
        value: "6405",
        text: "中卫市",
        children: [
          { value: "640502", text: "沙坡头区" },
          { value: "640521", text: "中宁县" },
          { value: "640522", text: "海原县" },
        ],
      },
    ],
  },
  {
    value: "65",
    text: "新疆维吾尔自治区",
    children: [
      {
        value: "6501",
        text: "乌鲁木齐市",
        children: [
          { value: "650102", text: "天山区" },
          { value: "650103", text: "沙依巴克区" },
          { value: "650104", text: "新市区" },
          { value: "650105", text: "水磨沟区" },
          { value: "650106", text: "头屯河区" },
          { value: "650107", text: "达坂城区" },
          { value: "650109", text: "米东区" },
          { value: "650121", text: "乌鲁木齐县" },
        ],
      },
      {
        value: "6502",
        text: "克拉玛依市",
        children: [
          { value: "650202", text: "独山子区" },
          { value: "650203", text: "克拉玛依区" },
          { value: "650204", text: "白碱滩区" },
          { value: "650205", text: "乌尔禾区" },
        ],
      },
      {
        value: "6504",
        text: "吐鲁番市",
        children: [
          { value: "650402", text: "高昌区" },
          { value: "650421", text: "鄯善县" },
          { value: "650422", text: "托克逊县" },
        ],
      },
      {
        value: "6505",
        text: "哈密市",
        children: [
          { value: "650502", text: "伊州区" },
          { value: "650521", text: "巴里坤哈萨克自治县" },
          { value: "650522", text: "伊吾县" },
        ],
      },
      {
        value: "6523",
        text: "昌吉回族自治州",
        children: [
          { value: "652301", text: "昌吉市" },
          { value: "652302", text: "阜康市" },
          { value: "652323", text: "呼图壁县" },
          { value: "652324", text: "玛纳斯县" },
          { value: "652325", text: "奇台县" },
          { value: "652327", text: "吉木萨尔县" },
          { value: "652328", text: "木垒哈萨克自治县" },
        ],
      },
      {
        value: "6527",
        text: "博尔塔拉蒙古自治州",
        children: [
          { value: "652701", text: "博乐市" },
          { value: "652702", text: "阿拉山口市" },
          { value: "652722", text: "精河县" },
          { value: "652723", text: "温泉县" },
        ],
      },
      {
        value: "6528",
        text: "巴音郭楞蒙古自治州",
        children: [
          { value: "652801", text: "库尔勒市" },
          { value: "652822", text: "轮台县" },
          { value: "652823", text: "尉犁县" },
          { value: "652824", text: "若羌县" },
          { value: "652825", text: "且末县" },
          { value: "652826", text: "焉耆回族自治县" },
          { value: "652827", text: "和静县" },
          { value: "652828", text: "和硕县" },
          { value: "652829", text: "博湖县" },
          { value: "652871", text: "库尔勒经济技术开发区" },
        ],
      },
      {
        value: "6529",
        text: "阿克苏地区",
        children: [
          { value: "652901", text: "阿克苏市" },
          { value: "652922", text: "温宿县" },
          { value: "652923", text: "库车县" },
          { value: "652924", text: "沙雅县" },
          { value: "652925", text: "新和县" },
          { value: "652926", text: "拜城县" },
          { value: "652927", text: "乌什县" },
          { value: "652928", text: "阿瓦提县" },
          { value: "652929", text: "柯坪县" },
        ],
      },
      {
        value: "6530",
        text: "克孜勒苏柯尔克孜自治州",
        children: [
          { value: "653001", text: "阿图什市" },
          { value: "653022", text: "阿克陶县" },
          { value: "653023", text: "阿合奇县" },
          { value: "653024", text: "乌恰县" },
        ],
      },
      {
        value: "6531",
        text: "喀什地区",
        children: [
          { value: "653101", text: "喀什市" },
          { value: "653121", text: "疏附县" },
          { value: "653122", text: "疏勒县" },
          { value: "653123", text: "英吉沙县" },
          { value: "653124", text: "泽普县" },
          { value: "653125", text: "莎车县" },
          { value: "653126", text: "叶城县" },
          { value: "653127", text: "麦盖提县" },
          { value: "653128", text: "岳普湖县" },
          { value: "653129", text: "伽师县" },
          { value: "653130", text: "巴楚县" },
          { value: "653131", text: "塔什库尔干塔吉克自治县" },
        ],
      },
      {
        value: "6532",
        text: "和田地区",
        children: [
          { value: "653201", text: "和田市" },
          { value: "653221", text: "和田县" },
          { value: "653222", text: "墨玉县" },
          { value: "653223", text: "皮山县" },
          { value: "653224", text: "洛浦县" },
          { value: "653225", text: "策勒县" },
          { value: "653226", text: "于田县" },
          { value: "653227", text: "民丰县" },
        ],
      },
      {
        value: "6540",
        text: "伊犁哈萨克自治州",
        children: [
          { value: "654002", text: "伊宁市" },
          { value: "654003", text: "奎屯市" },
          { value: "654004", text: "霍尔果斯市" },
          { value: "654021", text: "伊宁县" },
          { value: "654022", text: "察布查尔锡伯自治县" },
          { value: "654023", text: "霍城县" },
          { value: "654024", text: "巩留县" },
          { value: "654025", text: "新源县" },
          { value: "654026", text: "昭苏县" },
          { value: "654027", text: "特克斯县" },
          { value: "654028", text: "尼勒克县" },
        ],
      },
      {
        value: "6542",
        text: "塔城地区",
        children: [
          { value: "654201", text: "塔城市" },
          { value: "654202", text: "乌苏市" },
          { value: "654221", text: "额敏县" },
          { value: "654223", text: "沙湾县" },
          { value: "654224", text: "托里县" },
          { value: "654225", text: "裕民县" },
          { value: "654226", text: "和布克赛尔蒙古自治县" },
        ],
      },
      {
        value: "6543",
        text: "阿勒泰地区",
        children: [
          { value: "654301", text: "阿勒泰市" },
          { value: "654321", text: "布尔津县" },
          { value: "654322", text: "富蕴县" },
          { value: "654323", text: "福海县" },
          { value: "654324", text: "哈巴河县" },
          { value: "654325", text: "青河县" },
          { value: "654326", text: "吉木乃县" },
        ],
      },
      {
        value: "6590",
        text: "自治区直辖县级行政区划",
        children: [
          { value: "659001", text: "石河子市" },
          { value: "659002", text: "阿拉尔市" },
          { value: "659003", text: "图木舒克市" },
          { value: "659004", text: "五家渠市" },
          { value: "659006", text: "铁门关市" },
        ],
      },
    ],
  },
  {
    children: [
      {
        children: [
          { text: "中正区", value: "710101" },
          { text: "大同区", value: "710102" },
          { text: "中山区", value: "710103" },
          { text: "松山区", value: "710104" },
          { text: "大安区", value: "710105" },
          { text: "万华区", value: "710106" },
          { text: "信义区", value: "710107" },
          { text: "士林区", value: "710108" },
          { text: "北投区", value: "710109" },
          { text: "内湖区", value: "710110" },
          { text: "南港区", value: "710111" },
          { text: "文山区", value: "710112" },
          { text: "其它区", value: "710113" },
        ],
        text: "台北市",
        value: "710100",
      },
      {
        children: [
          { text: "新兴区", value: "710201" },
          { text: "前金区", value: "710202" },
          { text: "芩雅区", value: "710203" },
          { text: "盐埕区", value: "710204" },
          { text: "鼓山区", value: "710205" },
          { text: "旗津区", value: "710206" },
          { text: "前镇区", value: "710207" },
          { text: "三民区", value: "710208" },
          { text: "左营区", value: "710209" },
          { text: "楠梓区", value: "710210" },
          { text: "小港区", value: "710211" },
          { text: "其它区", value: "710212" },
        ],
        text: "高雄市",
        value: "710200",
      },
      {
        children: [
          { text: "中西区", value: "710301" },
          { text: "东区", value: "710302" },
          { text: "南区", value: "710303" },
          { text: "北区", value: "710304" },
          { text: "安平区", value: "710305" },
          { text: "安南区", value: "710306" },
          { text: "其它区", value: "710307" },
        ],
        text: "台南市",
        value: "710300",
      },
      {
        children: [
          { text: "中区", value: "710401" },
          { text: "东区", value: "710402" },
          { text: "南区", value: "710403" },
          { text: "西区", value: "710404" },
          { text: "北区", value: "710405" },
          { text: "北屯区", value: "710406" },
          { text: "西屯区", value: "710407" },
          { text: "南屯区", value: "710408" },
          { text: "其它区", value: "710409" },
        ],
        text: "台中市",
        value: "710400",
      },
      { text: "金门县", value: "710500" },
      { text: "南投县", value: "710600" },
      {
        children: [
          { text: "仁爱区", value: "710701" },
          { text: "信义区", value: "710702" },
          { text: "中正区", value: "710703" },
          { text: "中山区", value: "710704" },
          { text: "安乐区", value: "710705" },
          { text: "暖暖区", value: "710706" },
          { text: "七堵区", value: "710707" },
          { text: "其它区", value: "710708" },
        ],
        text: "基隆市",
        value: "710700",
      },
      {
        children: [
          { text: "东区", value: "710801" },
          { text: "北区", value: "710802" },
          { text: "香山区", value: "710803" },
          { text: "其它区", value: "710804" },
        ],
        text: "新竹市",
        value: "710800",
      },
      {
        children: [
          { text: "东区", value: "710901" },
          { text: "西区", value: "710902" },
          { text: "其它区", value: "710903" },
        ],
        text: "嘉义市",
        value: "710900",
      },
      { text: "新北市", value: "711100" },
      { text: "宜兰县", value: "711200" },
      { text: "新竹县", value: "711300" },
      { text: "桃园县", value: "711400" },
      { text: "苗栗县", value: "711500" },
      { text: "彰化县", value: "711700" },
      { text: "嘉义县", value: "711900" },
      { text: "云林县", value: "712100" },
      { text: "屏东县", value: "712400" },
      { text: "台东县", value: "712500" },
      { text: "花莲县", value: "712600" },
      { text: "澎湖县", value: "712700" },
    ],
    text: "台湾省",
    value: "710000",
  },
  {
    children: [
      {
        children: [
          { text: "中西区", value: "810101" },
          { text: "湾仔", value: "810102" },
          { text: "东区", value: "810103" },
          { text: "南区", value: "810104" },
        ],
        text: "香港岛",
        value: "810100",
      },
      {
        children: [
          { text: "九龙城区", value: "810201" },
          { text: "油尖旺区", value: "810202" },
          { text: "深水埗区", value: "810203" },
          { text: "黄大仙区", value: "810204" },
          { text: "观塘区", value: "810205" },
        ],
        text: "九龙",
        value: "810200",
      },
      {
        children: [
          { text: "北区", value: "810301" },
          { text: "大埔区", value: "810302" },
          { text: "沙田区", value: "810303" },
          { text: "西贡区", value: "810304" },
          { text: "元朗区", value: "810305" },
          { text: "屯门区", value: "810306" },
          { text: "荃湾区", value: "810307" },
          { text: "葵青区", value: "810308" },
          { text: "离岛区", value: "810309" },
        ],
        text: "新界",
        value: "810300",
      },
    ],
    text: "香港特别行政区",
    value: "810000",
  },
  {
    children: [
      {
        children: [
          { text: "大堂区", value: "820100" },
          { text: "风顺堂区", value: "820100" },
          { text: "花地玛堂区", value: "820100" },
          { text: "花王堂区", value: "820100" },
          { text: "望德堂区", value: "820100" },
          { text: "其他区", value: "820100" },
        ],
        text: "澳门半岛",
        value: "820100",
      },
      {
        children: [
          { text: "嘉模堂区", value: "820200" },
          { text: "路凼填海区", value: "820200" },
          { text: "圣方济各堂区", value: "820200" },
          { text: "其他区", value: "820200" },
        ],
        text: "离岛",
        value: "820200",
      },
    ],
    text: "澳门特别行政区",
    value: "820000",
  },
];
export default address;
